@charset "UTF-8";

/*! hubub.plone.ubtheme
 * @version 1.2.0
 * @build 2023-10-24 10:31
 */


/*! base_content.css
 *  @version 0.2
 */

/**
 *  HISTORY
 *  ============================================================================
 *
 *  v0.2
 *  ----------------------------------------------------------------------------
 *  - #content h2 - border-bottom: 0 none - added
 *  - color setting now !important for
 *    - #content h2 > a
 *    - #content h3 > a
 *    - #content h4 > a
 *  - updated styles for #col3_content hr
 *  - added styles for #content span.discreet - list item content
 *
 */
@media all {

/* BASIC SETUP FOR CONTENT */
  /* primary font, font-size & color */
  /* html, body {
    font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
    color: #3d3d3d;
    font-size: 14px;
  } */

  /* headlines */
  /* #col3_content h1,
  #content h1 {
    font-size: 1.5em;
    font-weight: bold;
    color: #069;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.2em;
    margin-bottom: 0.5em;
  } */
  /* #col3_content h2,
  #content h2 {
    margin-top:2.5em;
    margin-bottom:.5em;
    border-top:1px solid #ccc;
    border-bottom: 0 none;
    font-size:1.25em;
  }
  #col3_content h2,
  #content h2,
  #col3_content h2 > a,
  #content h2 > a {
    font-weight: bolder;
    color: #8a0f14 !important;
    padding-top: 0.5em;
  } */
  /* #col3_content h2:first-of-type,
  #content h2:first-of-type {
    border: 0 none;
    margin-top:0.5em;
  } */
  /* #col3_content h3,
  #content h3 {
    margin-top:     1.25em;
    margin-bottom:  0.5em;
  }
  #col3_content h3,
  #content h3,
  #col3_content h3 > a,
  #content h3 > a {
    font-size: 1em;
    font-weight: bolder;
    color: #003d7f !important;
  } */
  /* #col3_content h4,
  #content h4 {
    margin-top:     0.75em;
    margin-bottom:  0.5em;
  }
  #col3_content h4,
  #content h4,
  #col3_content h4 > a,
  #content h4 > a {
    font-size: 1em;
    font-weight: bolder;
    color: #3d3d3d !important;
  } */
  /* text */
  /* #col3_content p,
  #content p {
    line-height: 1.5em;
    margin-bottom: 1em;
  } */
  /* #col3_content b,
  #col3_content strong,
  #content b,
  #content strong {
    font-weight: bold;
  } */

/* horizontal lines */
  /* #col3_content hr {
    border: 0 none;
    margin: 0.5em 0;
    color: #ccc;
    background-color: #ccc;
    height: 1px;
  } */

/* preformatted Text */
  /* #col3_content pre,
  #content pre {
    border-left: 0.75em solid #8a0f14;
    font-style: italic;
    padding: 0 0 0 1em;
    font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
    display: block;
    white-space: normal;
    margin-bottom: 1.5em;
    line-height: 1.5em;
  } */
/* discreet span - turns li-items into dark grey */
  /* #col3_content span.discreet,
  #content span.discreet {
    color: #3d3d3d;
    font-size: 1em;
    font-weight: normal;
  } */
/* unhighlite terms of glossary*/
  .highlightedGlossaryTerm {
    border: 0 none !important;
    background: transparent !important;
    cursor: default !important;
  }

}



/*! fachseite.css
 * fachseite styles
 * @author Heiko Miersch <mierschh@ub.hu-berlin.de>
 *
 */
@media all {
  .template-fachseite_view div.fachseiteNav {
    background-color: #e6e6e6;
    margin: -0.5em 0 1em 0; /* get the menu closer to headline */
    line-height: 2em;
  }
  .template-fachseite_view div.fachseiteNav .subcl {
    border-right: 2px solid #fff;
  }
  .template-fachseite_view div.fachseiteNav .subcl,
  .template-fachseite_view div.fachseiteNav .subcr {
    padding: 0 0.25em 0 0.25em;
    text-align: center;
  }
  .template-fachseite_view div.fachseiteNav a {
    font-weight: bold;
    font-size: 0.9166em;
    text-decoration: none !important;
  }
  .template-fachseite_view div.fachseiteNav a {
    color: #069;
  }
/*  .template-fachseite_view div.fachseiteNav a:visited,*/
  .template-fachseite_view div.fachseiteNav a:hover,
  .template-fachseite_view div.fachseiteNav a:active,
  .template-fachseite_view div.fachseiteNav a:focus {
    color: #3d3d3d;
  }
  .template-fachseite_view h2 a {
    text-decoration: none;
  }
  .template-fachseite_view ul p {
    color: #000;
    margin-left: -0.2em;
  }
}


/*! images.css
 *  styles for images
 *  @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  @version 0.1 
 */
@media all {

/* images without captions */
  #col3_content img,
  #content img {
    vertical-align: middle;
  }
  #col3_content dl.image-right,
  #content dl.image-right,
  #col3_content img.image-right,
  #content img.image-right{
    display: inline;
    float: right;
    margin: 0.2em 0 0.2em 1em;
  }
  #col3_content dl.image-left,
  #content dl.image-left,
  #col3_content img.image-left,
  #content img.image-left{
    display: inline;
    float: left;
    margin: 0.2em 1em 0.2em 0;
  }
  #col3_content dl.center,
  #content dl.center,
  #col3_content img.center,
  #content img.center{
    display: block;
    margin: 0.5em auto;
    text-align: center;
  }
/* images width captions */
  #col3_content dl.captioned dd.image-caption{
    background: none repeat scroll 0 0 #e6e6e6;
    font-size: 0.9166em;
    font-weight: normal;
    margin-top: 0.3em;
    overflow: hidden;
    padding: 0.3em 0.5em;
  }

}

/*! hubub.plone.ubtheme
 * @version 1.2.0
 * @build 2023-10-24 10:31
 */


/*! base_views-mobile.css
 *  responsive adaptions for the plone base-views
 *  @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  @version 0.1 
 */
@media (max-width: 599px) {
/* folder_summary_view - Kurzfassung */
  /* bei kleinem Bildschirm -> Spalten auflösen */
  body.template-folder_summary_view #col3_content .subcolumns .col_w13l,
  body.template-folder_summary_view #col3_content .subcolumns .col_w87r {
   float: none;
  }
  /* rechte spalte leicht einrücken */
  body.template-folder_summary_view #col3_content .tileItem .subcr {
    padding-left: 0.5em;
  }
}
@media (max-width: 800px) {
/* paginator skins/batch_macro.pt */
    #col3_content .listingBar {
    width: 100%;
    background-color: #fff;
    color: #3d3d3d;
    clear: both;
    text-align: center;
    line-height: 2em;
  }
  #col3_content .listingBar > span {
    border: 1px solid #ccc;
    border-left: 0;
    text-align: center;
    padding: 0.3em .8em;
    vertical-align: middle;
    margin: 0;
  }
  #col3_content .listingBar > span:hover {
    background-color: #e6e6e6;
  }
  #col3_content .listingBar > span.hellip:hover,
  #col3_content .listingBar > span.inactive:hover {
    background-color: #fff;
  }
  #col3_content .listingBar .previous,
  #col3_content .listingBar .current,
  #col3_content .listingBar .next {
    margin: 0 0 0 0;
  }
  #col3_content .listingBar .current + span {
    margin-left: -.3em;
  }
  #col3_content .listingBar a,
  #col3_content .listingBar .inactive,
  #col3_content .listingBar .hellip {
    text-decoration: none;
  }
  #col3_content .listingBar .current {
    background-color: #e6e6e6;
    font-weight: bold;
  }
  #col3_content .listingBar .previous {
    border-left: 1px solid #ccc; /**/
    border-radius: 4px 0 0 4px; /**/
  }
  #col3_content .listingBar .next {
    border-radius: 0 4px 4px 0; /**/
  }
  /* text verstecken */
  #col3_content .listingBar .infoTxt {
    display: none;
  }
/* plone login form */
  /* #login-form label {
    float: none;
  }
  #login-form input {
    margin-left: 0;
    width: 100%;
  }
  #login-form .buttonform input[type="submit"] {
    margin-left: 0;
  } */
}



/*! content_backend-mobile.css
 *  mobile backend styles for content area
 */
@media (max-width: 800px) {
  #col3 #messages {
    margin: 0.5em 0;
  }
  
}


/*! news-mobile.css
 *  styling rules for small displays
 */
@media (max-width: 415px) {
  
  #col3_content .topnews_wrapper .topnews .topnews_content {
    margin: 0.8em 0.3em 0.5em 0.5em;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content .subcolumns .col_w80l,
  #col3_content .news_wrapper .news_header .col_w80l {
    width: 100%;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content .subcolumns .col_w20r,
  #col3_content .news_wrapper .news_header .col_w20r {
    width: 0%;
    display: none;
  }
}

@media (max-width: 800px) {
  #col3_content .topnews_wrapper .topnews > .subcolumns > .col_w20l {
     width: 80px;
  }
  #col3_content .topnews_wrapper .topnews > .subcolumns > .col_w80r {
    width: auto;
    float: none;
    z-index: 0;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content {
    margin-left: 85px;
  }
}


/*! portlets-mobile.css
 *  responsive adaptions for portlets
 *  @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  @version 0.1 
 */
@media (max-width: 800px) {
  div.portletWrapper {
    position: inherit;
  }
  
  div.portletWrapper.nomobile.desktop {
    display: none;
  }
  div.portletWrapper:last-child {
    margin-bottom: 0;
  }
  div.staticPortlet {
    margin-left: 5px;
    margin-bottom: 0;
  }
}


/*! standortbox-mobile.css
 *  responsive adaptions for standortbox.css
 *  @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  @version 0.1 
 */
@media (max-width: 400px) {
  
  #col3_content .standortbox-document .col_w80r .subcr {
    margin: 0.4em 0.3em 0.5em 85px;
  }
}

@media (max-width: 800px) {
  #col3_content .standortbox-document > .col_w20l {
    width: 80px;
  }
  #col3_content .standortbox-document .col_w80r {
    width: auto;
    float: none;
    z-index: 0;
  }
  #col3_content .standortbox-document .col_w80r > .subcr {
    margin-left: 85px;
  }
  #col3_content .standortbox-document h3 {
    margin-top: 0;
  }
}


/*! subNavigationPortlet-mobile.css
 * left column navigation mobile styles
 * @author Heiko Miersch <mierschh@ub.hu-berlin.de>
 *
 */
@media (max-width: 800px) {
  /* sub navigation portlet */
  #subNavigationPortlet {
    margin-bottom: 0.25em;

  }
}


/*! hubub.plone.ubtheme
 * @version 1.2.0
 * @build 2023-10-24 10:31
 */


/*! print.css */
@media print {

  #col1,
  #col2,
  #huub_search,
  #mainmenu,
  #contentviews,
  #contentactions,
  #messages,
  #footer .subcolumns,
  #userpanel,
  .rss {
    display: none;
  }
  #header .subcl {
    padding-top:3.2em;
    margin-left: -2.5em;
/*    position: relative;*/
/*    z-index: 1000;/
/*    zoom: 0.75;*/
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
  }
  #header .subcr {
    margin-left: -2em;
/*    zoom: 0.75;*/
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
  }
  h1, h2, h3, h4, h5,
  a {
    color: #000!important;
  }
}

/*! hubub.plone.ubtheme
 * @version 1.2.0
 * @build 2023-10-24 10:31
 */

/*! at_babel_edit.css
 *  rules for babel-edit-view
 */
@media screen, projection {

  #babel-edit #frame-content {
    padding: 0!important;
  }
  #babel-edit #frame-content .field {
    padding: 1.5em!important;
    margin-top: 0.5em;
  }
  #babel-edit #frame-content .field label {
    font-weight: bold;
    margin-left: -0.5em;
  }
  #babel-edit #frame-content .field label:before,
  #babel-edit #frame-content .field label:after {
    content: " ### ";
  }
  
}



/*! collage.css
 *  Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  styles for Products.Collage
 */
@media screen, projection {

/* edit section */
  #col3_content #collage.edit .collage-header {
    line-height: 1.25em;
  }
  #col3_content #collage.edit .collage-row .row_1 > .collage-header {
    height: 2em;
  }
  #col3_content #collage.edit .collage-header span {
    color: #000;
  }
  #col3_content #collage.edit input#insert-collage-row {
    margin-left: 1em;
  }
  #col3_content #collage.edit .visualIcon,
  #col3_content #collage.edit .collage-row .collage-header .visualClear,
  #col3_content #collage.edit .False .visualClear {
    clear: both;
  }
  #col3_content #collage.edit .collage-row {
    clear: none;
  }
  #col3_content #collage.edit .expandable-content {
    background-color: #dcdcdc;
  }
  #col3_content #collage.edit .expandable-content h4 {
    background-color: #ff9;
  }

/* view section */
  #col3_content #collage .collage-row {
    clear: none;
  }
  
/* layout */
  /* two equal cols */
  #col3_content #collage .two-columns-equal .block_1,
  #col3_content #collage .two-columns-equal .block_2 {
    width: 48%;
  }
  #col3_content #collage .two-columns-equal .block_2 {
    padding-left: 3%;
  }

}


/*!content_backend.css
 * backend styles for content area
 * @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 * @version 1.2
 */
/*  History
 *  ----------------------------------------------------------------------------
 *  
 *  v1.2
 *  ----------------------------------------------------------------------------
 *  - add styling for diff view
 *  - add improved positioning for PloneFormGen quickedit
 *
 *  v1.1
 *  ----------------------------------------------------------------------------
 *  - remove useless entry for cke.tables
 *  - add styles for overlay menu to get related objects
 *
 *  v1.0
 *  ----------------------------------------------------------------------------
 *  - initial setup
 */
@media screen, projection {

  /* #col3_content .documentDescription {
    margin: 1em 0 1.5em 0;
    font-weight: bolder;
    line-height: 1.5em;
  } */
  /* #col3_content form  div {
    margin: 0.2em 0;
  } */
  /* #col3_content form div.field {
    margin: 0.5em 0;
  } */
  /* #col3_content form div label {
    font-weight: bold;
  } */
  #col3_content form input.checkboxType {
    margin-right: 0.5em;
  }
  /* #col3_content form input[type="submit"] {
    margin: 0 0.5em 0 0;
  } */

/* DocumentByLine */
  #col3_content .documentByLine {
    margin: 0.5em 0;
  }
/* DocumentActions */
  #col3_content .documentActions {
    margin: 1em 0 0 0;
  }



/* login form backend */
/*
  #col3 #login-form input {
    margin-left: 5%;
  }
  #col3 #login-form .buttonform input[type="submit"] {
    margin: 0 0 0 30%!important;
  }
*/
/* wildcard foldercontents */
  /* content types */
  /* .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-folder {
    background: no-repeat transparent 0px 0px url(/folder_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-topic {
    background: no-repeat transparent 0px 0px url(/topic_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-document {
    background: no-repeat transparent 0px 0px url(/document_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-file {
    background: no-repeat transparent 0px 0px url(/file_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-image {
    background: no-repeat transparent 0px 0px url(/image_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-link {
    background: no-repeat transparent 0px 0px url(/link_icon.png);
  }
  .icons-on #col3_content #folderContentsForm table tbody td a.contenttype-news-item {
    background: no-repeat transparent 0px 0px url(/newsitem_icon.png);
  } */
  /* styling */
  #col3_content #folderContentsForm table {
    width: 100%;
  }
  .icons-on #col3_content #folderContentsForm table tbody td a {
    padding-left: 1.5em;
    text-decoration: none;
  }
  .icons-on #col3_content #folderContentsForm table tbody td img {
    margin-right: -1em;
  }
  .icons-on #col3_content #folderContentsForm table tbody td img + a {
    background: 0 none !important;
    padding-left: 0;
    margin-left: 1em;
  }

/* CKEditor */
/*   table.cke_editor {
    width: 100% !important;
  } */

/* info tags */
  #col3_content .state-expired {
    background-color: #f55;
    color: #fff;
    padding: 0 0.2em;
    font-weight: bold;
  }
/* History view */
  .historyRecord {
    margin: 0.25em 0;
    background-color: #e6e6e6;
    padding-left: 0.25em;
  }
  .historyRecord .historyTools,
  .historyRecord .historyByLine,
  .historyRecord .historyComparePrevious {
    display: inline-block;
  }
  .historyRecord .historyComparePrevious {
    margin: 0.25em 0;
  }
  .historyRecord .historyLinks {
    margin-left: 1.25em;
  }
  .historyRecord .historyComment {
    display: none;
  }
/* Diff view */
  #diff-table
  {
    margin-bottom: 0;
  }
  #diffTypeSelector,
  #diffTypeSelector li
  {
    list-style: inside;
    list-style-type: none;
  }
  #diff-legend + form
  {
    padding-bottom: 1em;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1em;
  }
  #diff-legend + form:after
  {
    content: ".";
    visibility: hidden;
    display: block;
    font-size: 0;
    height: 0;
    clear: right;
  }
  /* fieldset.diff
  {
  } */
  fieldset.diff legend
  {
    font-weight: bolder;
  }
  fieldset.diff .fieldDiff.inline-diff
  {
    padding: 0;
  }

/* Edit Forms */
  /* #col3_content span.formHelp {
    display: block;
    color: #666;
    margin: 0.25em 0 0.5em;
    font-weight: normal;
  } */
  /* edit tabs */
  #col3_content ul.formTabs {
    margin-left: 0;
    padding-left: 0.5em;
    border-bottom: 2px solid #666;
  }
  #col3_content ul.formTabs li.formTab {
    display: inline-block;
  }
  #col3_content ul.formTabs li.formTab a.selected {
    background-color: #fff;
    text-decoration: none;
  }
  #col3_content ul.formTabs li.formTab a {
    display: inline-block;
    padding: 0 1em;
    border-left: 1px solid #666;
    border-right: 1px solid #666;
    border-top: 1px solid #666;
    border-radius: 0.25em 0.25em 0 0;
    background-color: #e3e3e3;
  }
  /* relations choice */
  div.overlay {
    background-color: #fff;
    border: 1px solid #3d3d3d;
    box-shadow: 0 0 3em rgba(0, 0, 0, 0.5);
    padding: 0.5em;
    min-width: 50%;
  }
  div.overlay .close {
    cursor: pointer;
    text-align: right;
    margin-bottom: 0.5em;
  }
  div.overlay .close span:after {
    content: '⊗';
  }
  div.overlay .pb-ajax {
    font-size: 80%; /* decrease font-size to get the normal font-size in overlay */
  }
  div.overlay .pb-ajax .overlaycontent div#messageWrapper dt {
    float: left;
    margin-right: 1em;
  }
  div.overlay .pb-ajax form#search {
    float: right;
  }
  #atrbResultsTable .even {
    background-color: #e3e3e3;
  }
  #atrbResultsTable tr {
    line-height: 1.5em;
  }
  #atrbResults .listingBar {
    margin-top: 0.5em;
    padding-top: 0.25em;
    border-top: 1px solid #3d3d3d;
    text-align: center;
  }
  #atrbResults .listingBar .current:before {
    content: '[';
  }
  #atrbResults .listingBar .current:after {
    content: ']';
  }
  /* PloneFormGen quickedit */
  #col3_content #pfgWidgetWrapper
  {
    position: absolute;
    right: 0px;
  }
  #col3_content #pfgThanksEdit a + a
  {
    margin-left: 0.5em;
  }
}



/*! forms.css */
@media screen, projection {

  .ubform {
    overflow: hidden;
  }
  
  .ubform legend {
    margin: 0.5em 0;
  }

  /* float clearing */
  fieldset:after,
  .ubform div.textform:after,
  .ubform div.selectform:after,
  .ubform div.checkform:after,
  .ubform div.buttonform:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }
  /* help messages for failures */
  .ubform div.error .msg {
    color: #f33;
  }
  .ubform div.textform.error input {
    border: 2px solid #f33;
  }
  /* styling containing DIV elements */
  .ubform div.textform,
  .ubform div.selectform,
  .ubform div.checkform,
  .ubform div.buttonform {
    display: block;
    margin: 0.2em 0;
    padding: 0 0.2em 0 0;
    position: relative;
    zoom: 1;
  }

  .ubform .textform input,
  .ubform .textform textarea,
  .ubform .textform select {
    border-color: #666 #ddd #ddd #666;
    border-width: 1px;
    border-style: solid;
  }

  .ubform .checkform label {
    margin-left: 0.5em;
  }

  /* columnar styling for forms */
  .colform .textform label,
  .colform .selectform label {
    color: #000;
    float: left;
    width: 40%; /* auch fixer wert möglich */
  }
  .colform div.checkform input {
    margin-left: 30%;
  }
  .colform div.error .msg {
    margin-left: 30%;
  }
  .colform fieldset div.buttonform,
  fieldset.colform div.buttonform {
    padding-left: 30%;
  }
  .colform div.textform input,
  .colform div.textform textarea {
    float: left;
    width: 50%;
    margin-right: -3px;
  }
  .colform div.textform select {
    float: left;
    width: 69.4%;
    margin-right: -3px;
  }
  /* Breitenanpassungen für IE 5.x & IE6 */
  * html .colform div.textform input,
  * html .colform div.textform textarea {
    width: 67.2%;
  }
  * html .colform div.selectform select {
    width: 68.8%;
  }

  /* global css form fixes */
  .ubform, .ubform div,
  .ubform div * {
    zoom: 1;
  }

  /* all IE */
  .ubform {
    padding-top: 0\9;
  }
  .ubform fieldset {
    padding: 0 5px\9;
    padding-top: 1em\9;
  }
  .ubform legend {
    position: absolute\9;
    top: -.5em \9;
    *left: 0\9;
  }
  .ubform fieldset {
    position: relative\9;
    overflow: visible\9;
    margin-top: 1.5em \9;
    zoom: 1;
  }

  /* IE5.x, IE6 & IE7 */
  .ubform legend {
    *padding: 0 5px;
  }
  .ubform fieldset {
    *padding-top: 1.5em;
  }

  /* IE5.x & IE6 */
  * html .ubform {
    padding-top: 10px;
  }

}


/*! jquery.iportlet.css
 *  Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  styles for jquery.iportlet.js plugin
 *  substitutes the iwant-plugin css
 */
@media screen, projection {
  /* global styles */
  .IPortlet {
    color: #666;
    font-weight: bold;
    margin: -0.5em;
  }
  .IPortlet .IPortlet_hide {
    display: none;
  }
  /* head */
  .IPortlet .IPortlet_head {
    cursor: pointer;
    clear: both;
    height: 30px;
  }
  .IPortlet .IPortlet_head .IPortlet_img {
    border-right: 1px solid #DCDCDC;
    margin-right: 0.5em;
    float: left;
  }
  .IPortlet .IPortlet_head div {
    vertical-align: middle;
    display: inline-block;
  }
  .IPortlet .IPortlet_head .IPortlet_txt {
    float: left;
    vertical-align: middle;
    padding: 0.4em 0 0 0;
  }
  .IPortlet .IPortlet_head .sprite {
    float: right;
    width: 25px;
    height: 25px;
    margin-top: 0.2em;
  }
  /* dropdown */
  .IPortlet .IPortlet_drop {
    
  }
  .IPortlet .IPortlet_drop ul {
    list-style-type: none;
    display: block;
    float: none;
  }
  .IPortlet .IPortlet_drop ul li {
    display: block;
    list-style-type: none;
    float: none;
  }
  .IPortlet .IPortlet_drop ul li a:before {
    content: "... ";
  }
  .IPortlet .IPortlet_drop ul li a {
    padding: 0.25em .2em 0.25em 1.5em;
    display: block;
  }
  .IPortlet .IPortlet_drop ul li a:hover{
    background-color: #EDEDED;
  }
}


/*! jquery.toogleStage.css */
@media screen, projection {
/* close button */
  #closestage {
    position: absolute;
    bottom: 0;

    line-height: 1.2em;
    background-color: #f4f4f4;
    float: right;
    border-top-left-radius: 1.5em;
    opacity: 0.5;
  }
  #closestage[lang = de] {
    left: 68.5em; /* depending on font-size */
    width: 7.6em;
  }
  #closestage[lang = en] {
    left: 71.5em; /* depending on font-size */
    width: 4.6em;
  }
  #closestage span {
    font-weight: bold;
    color: #666;
    margin: 0em 0.2em 0.2em 0.5em;
    cursor: pointer;
  }
/* open button */
  #openstage {
    top: 0;
    line-height: 1.2em;
    background-color: #f4f4f4;
    float: right;
    border-bottom-left-radius: 1.5em;
    opacity: 1;
  }
/*  #openstage[lang = de] {
    left: 78.75em;
    width: 6.6em;
  }
  #openstage[lang = en] {
    left: 80.75em;
    width: 4.6em;
  }*/
  #openstage span {
    font-weight: bold;
    color: #666;
    margin: 0em 0.2em 0.2em 0.5em;
    cursor: pointer;
  }
}


/*! links.css */
@media screen, projection {

/* BASIC LINK SETUP */
  a {
    text-decoration: none;
    color: #069;
  }
  /* links@main- content */
  #col3_content a,
  #content a {
    text-decoration: underline;
  }
  /* no underline for
   * links@news
   * links@feeds
   * links@toc
   * links@mailto
   * links@anchor
   * links@OSM
   * links@documentActions
   */
  #col3_content .news_wrapper a,
  #col3_content .feeds a,
  #col3_content .toc a,
  #col3_content a.olButton,
  #col3_content a[href^="mailto"],
  #col3_content a[name],
  #col3_content .documentActions a,
  #content .news_wrapper a,
  #content .feeds a,
  #content .toc a,
  #content a.olButton,
  #content a[href^="mailto"],
  #content a[name] {
    text-decoration: none;
  }
  /* a:active, a:hover, a:focus {
    border: 0 none;
  } */
  
/* Links in headlines or headlines in links */
  /* #col3_content h2 > a,
  #col3_content h3 > a,
  #col3_content h4 > a {
    text-decoration: none !important;
  }
  #col3_content h2 > a[href]:hover,
  #col3_content h3 > a[href]:hover,
  #col3_content h4 > a[href]:hover {
    text-decoration: underline !important;
  } */
/*
  #col3_content h2 > a {
    color: #8a0f14;
  }
  #col3_content h3 > a {
    color: #003d7f;
  }
  #col3_content h4 > a {
    color: #3d3d3d;
  }
*/
/* LANGUAGE-LINKS */
  #mainmenu .subcr a.lang {
    color: #000;
    text-decoration: underline;
  }

/* UP-LINKS | toplink */
  a[href="#top"] {
    border: 1px solid #666;
    border-radius: 2px;
    text-decoration: none !important;
    color: #3d3d3d;
    box-shadow: 0 0 0.2em #666;
    vertical-align: 0.1em;
  }
  #col3_content a[href="#top"] {
    font-size: 0.8571em;
  }
  a[href="#top"]:active,
  a[href="#top"]:hover,
  a[href="#top"]:focus {
    border: 1px solid #666;
    border-radius: 2px;
    /*background-color: #DCDCDC;*/
    text-decoration: none;
    box-shadow: 0 0 0.4em #666;
  }
  div.toplink {
    text-align: right;
    margin-top: -1.4em;
  }



/* FOOTER */
  /* A-Z- && Glossar-Links */
  /* #footer .subcolumns .subc a {
    text-decoration: none;
    vertical-align: 0;
  } */
  /* #footer .subcolumns .subc a:active,
  #footer .subcolumns .subc a:hover,
  #footer .subcolumns .subc a:focus {
    color: #000;
  } */
  /* UB-Login */
  /* #footer a.ublogin {
    text-decoration: none;
  } */
  /* #footer a.ublogin:active,
  #footer a.ublogin:hover,
  #footer a.ublogin:focus {
    color: #000;
  } */
}



/*! news.css
 *  news styles on startpage
 *  @author Heiko Miersch <mierschh@ub.hu-berlin.de>
 *  @version 0.2
 */

/**
 *  HISTORY
 *  ============================================================================
 *
 *  v0.2
 *  ----------------------------------------------------------------------------
 *  - on #col3_content .topnews_wrapper
 *    - refactor margin setting
 *    - add styles for padding, color, border
 *  - add styles for
 *    - #col3_content .topnews_wrapper .topnews .subcl .tileImage
 *    - #col3_content .news_wrapper .tileItem
 *  - set styles as !important on
 *    - #col3_content .topnews_wrapper .topnews .topnews_content a - color
 *    - #col3_content .topnews_wrapper .topnews .topnews_content h3 -color
 *    - #col3_content .news_wrapper .newest_news - padding-left|right
 *    - #col3_content .news_wrapper .news - padding-left|right|top
 *    - #col3_content .news_wrapper h3.news_title a - color
 *
 */
@media screen, projection {

/* top news */
  #col3_content .topnews_wrapper {
    /*margin-bottom: 0.5em;*/
    color: #fff;
    border: 0 none;
    padding: 0;
    margin: 0 0 0.5em 0;
  }
  #col3_content .topnews_wrapper .topnews {
    background-color: #003d7f;
  }
  #col3_content .topnews_wrapper .topnews .subcl,
  #col3_content .topnews_wrapper .topnews .subcr {
    padding: 0;
  }
  #col3_content .topnews_wrapper .topnews .subcl .tileImage {
    margin: 0;
    border: 0 none;
    float: none;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content {
    margin: 0.8em 0.3em 0.5em 0.5em;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content h3 {
    margin-top: 0!important;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content a,
  #col3_content .topnews_wrapper .topnews .topnews_content h3 {
    color: #fff !important;
    text-decoration: none;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content .news_more {
    text-align: right;
  }
  #col3_content .topnews_wrapper .topnews .topnews_content h3,
  #col3_content .topnews_wrapper .topnews .topnews_content p {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    line-height: 1.3em;
  }

/* simple news */
  #col3_content .news_wrapper {
    border: 1px solid #ccc;
  }
  #col3_content .news_wrapper .tileItem {
    border-top: 0 none;
    background: none;
    line-height: inherit;
    display: block;
    margin-top: 0 !important;
  }
  #col3_content .news_wrapper h3,
  #col3_content .news_wrapper p {
    margin-top: 0.4em;
    margin-bottom: 0.2em;
    line-height: 1.3em;
  }
  #col3_content .news_wrapper .newest_news,
  #col3_content .news_wrapper .news {
    padding-left: .5em !important;
    padding-right: .3em !important;
  }
  #col3_content .news_wrapper .newest_news p,
  #col3_content .news_wrapper .news p {
    padding-right: 1em;
  }
  #col3_content .news_wrapper .newest_news:last-child,
  #col3_content .news_wrapper .news:last-child p {
    border: 0 none;
  }
  #col3_content .news_wrapper .news_date {
    color: #3d3d3d;/*#00336c;*/
    font-weight: normal;/*bold;*/
  }
  #col3_content .news_wrapper .news_more {
    color: #069;
    text-align: right;
  }
/* newest_news */
    #col3_content .news_wrapper .newest_news {
    background: #e6e6e6;/*#f0e1b6;*//*#DCDCDC;*/
    border-bottom: 1px solid #fff;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
/* normal news */
  #col3_content .news_wrapper .news {
    padding-top: .5em !important;
/*    padding-bottom: 0.5em;*/
  }
  #col3_content .news_wrapper .news p:last-child {
/*    border-bottom: 1px solid #DCDCDC;*/
    padding-bottom: 0.5em;
  }
  #col3_content .news_wrapper .news:last-child :last-child {
    border-bottom: 0 none;
  }
  #col3_content .news_wrapper a {
    text-decoration: none;
  }
  #col3_content .news_wrapper h3.news_title a {
    color: #3d3d3d !important;
  }
  #col3_content .news_wrapper .news h3.news_border {
    margin-bottom: 0;
    padding-bottom: 0.5em;
  }
  #col3_content .news_wrapper .news h3.news_border,
  #col3_content .news_wrapper .news p.news_border {
      border-bottom:1px solid #DCDCDC;
  }
/* Feeds */
  #col3_content .feeds {
    text-align: right;
    font-style: italic;
    margin: 1em 0 0 0;
  }
  #col3_content .feeds a {
    line-height: 1.5em;
    color: #666;
  }
  #col3_content .feeds a:hover {
    color: #333;
  }
  #col3_content .feeds img {
    margin-left: 0.5em;
    vertical-align: baseline;
  }

}


/*! screen/plone-toolbar.css */
@media screen, projection {

.plone-toolbar-top-default {
  margin-top: 0;
}

#edit-zone nav>ul ul {
  font-size: 100%;
  line-height: 120%;
}
#edit-zone nav>ul ul li>span,
#edit-zone nav>ul ul li>a {
  line-height: 100%;
}

.plone-toolbar-top.plone-toolbar-expanded #edit-zone .plone-toolbar-logo,
#edit-zone .plone-toolbar-logo {
  width: auto;
}

}



/*! search-results.css
 *  @author mierschh@ub.hu-berlin.de
 *  @version 0.1
 *  styling rules for search results
 */
@media screen, projection {
  #search-result-number {
    margin-right: 0.25em;
  }
  .searchResults .searchResult {
    margin-bottom: 0.5em;
  }
  .searchResults .last {
    margin-bottom: 0;
  }
  .searchResults .searchResult .subcl {
    padding-right: 0.25em;
  }
  .searchResults .searchResult .subcr {
    margin-left: 0.5em!important;
  }
  .searchResults .searchResult .documentByLine {
    margin: .25em 0!important;
  }
  #content .searchResults .searchResult p
  #col3_content .searchResults .searchResult p {
    margin-bottom: 0;
  }
  #content .searchResults .search-advanced,
  #col3_content .searchResults .search-advanced {
    margin-bottom: 2em;
  }
  /* prevent margins in search form */
  #col3_content form .searchResults .searchResult div {
    margin: 0;
  }
  /* search result sorting options */
  #sorting-options .active {
    font-weight: bolder;
  }
  #sorting-options * {
    margin-right: 0.5em;
  }
}



/*! slider.css
 *  @author mierschh@ub.hu-berlin.de
 *  @version 0.1
 *  adjustments for collective.easyslider
 */
@media screen, projection {
  #slider-container {
    float: right;
    margin-left: 0.5em !important;
    margin-bottom: 0.5em !important;
  }
}


/*! standortbox.css
 *  for collage/standortbox.pt
 *  @author Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  @version 0.1 
 */
@media screen, projection {
  #col3_content .standortbox-document {
    background-color: #e6e6e6;
  }
  #col3_content .standortbox-document .subcl,
  #col3_content .standortbox-document .subcr {
    padding: 0;
  }
  #col3_content .standortbox-document h3 {
    margin-bottom: 0;
    font-size: 0.85714em;
  }
  #col3_content .standortbox-document h3 a {
    color: #3d3d3d;
    text-decoration: none;
  }
  #col3_content .standortbox-document .adresse p {
    margin-bottom: 0;
    font-size: 0.85714em;
  }
  #col3_content .standortbox-document .standortLink a {
    font-size: 0.85714em;
  }
  #col3_content .standortbox-document .col_w10r {
    background-color: #fff;
    height: 75px;
  }
}



/*! tinymce.css
 *  Heiko Miersch <heiko.miersch@ub.hu-berlin.de>
 *  styles for tinyMCEditor
 */
@media screen, projection {

  #col3_content form[name='edit_form'] fieldset,
  #col3_content #content-core form fieldset {
    width: 100%;
  }
  #col3_content form[name='edit_form'] fieldset table#text_tbl,
  #col3_content #content-core form fieldset table[id*="text_tbl"] {
    width: 100% !important;
  }
  #col3_content form[name='edit_form'] fieldset table[id*="block"] tbody tr:hover td,
  #col3_content form[name='edit_form'] fieldset table#text_tbl tbody tr:hover td,
  #col3_content #content-core form fieldset table[id*="text_tbl"] tbody tr:hover td {
    background: transparent;
  }
  #col3_content form[name='edit_form'] fieldset table[id*="block"] th,
  #col3_content form[name='edit_form'] fieldset table[id*="block"] td,
  #col3_content form[name='edit_form'] fieldset table[id*="block"] table.mceToolbar tbody tr,
  #col3_content form[name='edit_form'] fieldset table#text_tbl th,
  #col3_content form[name='edit_form'] fieldset table#text_tbl td,
  #col3_content form[name='edit_form'] fieldset table#text_tbl table.mceToolbar tbody tr,
  #col3_content #content-core form fieldset table[id*="text_tbl"] th,
  #col3_content #content-core form fieldset table[id*="text_tbl"] td,
  #col3_content #content-core form fieldset table[id*="text_tbl"] table.mceToolBar tbody tr {
    padding: 0;
    border: 0 none;
  }
  #col3_content form[name='edit_form'] fieldset table#text_tbl tbody tr,
  #col3_content form[name='edit_form'] fieldset table#text_tbl tbody th,
  #col3_content #content-core form fieldset table[id*="text_tbl"] table.mceToolbar tbody tr,
  #col3_content #content-core form fieldset table[id*="text_tbl"] table.mceToolbar tbody th {
    border: 0 none;
  }
  #col3_content form[name='edit_form'] fieldset table#text_tbl tbody tr,
  #col3_content #content-core form fieldset table[id*="text_tbl"] tbody tr {
    border: 1px solid #dcdcdc;
  }
  #col3_content form[name='edit_form'] fieldset table#text_tbl tbody td.mceToolbar,
  #col3_content #content-core form fieldset table[id*="text_tbl"] tbody td.mceToolbar {
    vertical-align: middle;
  }
  /* brings the last edit-menu in the next line */
  #col3_content form[name='edit_form'] fieldset table[id*="block"] table.mceToolbar:last-of-type,
  #col3_content form[name='edit_form'] fieldset table#text_tbl table.mceToolbar:last-of-type,
  #col3_content #content-core form fieldset table[id*="text_tbl"] table.mceToolbar:last-of-type {
    float: none;
  }
  /* more space between content & borders of edit-area */
  #content {
    padding: 0.2em 0.5em;
  }

  /* show always tablegrid in tinyMCEditor */
  .mceItemTable,
  .mceItemTable td,
  .mceItemTable th,
  .mceItemTable caption,
  .mceItemVisualAid {
    border: 1px dashed #ccc!important;
  }
  
  .mceContentBody table.listing {
    border-collapse: collapse!important;
    margin-bottom: 2em!important;
  }
  .mceContentBody table.listing tbody {
    border-bottom: 1px solid #ccc!important;
  }
  .mceContentBody table.listing tr,
  .mceContentBody table.listing td {
    border-left: 1px solid #ccc!important;
    border-right: 1px solid #ccc!important;
  }
  .mceContentBody table.listing tr th {
    border: 1px solid #ccc!important;
  }
  .mceContentBody table.listing th {
    background: none repeat scroll 0 0 #e6e6e6!important;
  }
  .mceContentBody table.listing td {
    border-bottom: 1px dashed #ccc!important;
  }
  .mceContentBody table th,
  .mceContentBody table td {
    padding: 0.5em!important;
  }
}

/*  mixings for flex containers
 *  @author Heiko Miersch <mierschh@ub.hu-berlin.de>
 *  @version 0.1
 */
/** HISTORY
 *  ============================================================================
 *  v0.1
 *  ----------------------------------------------------------------------------
 *  - initial release
 *  
 */
/* Rules for sizing the icon. */
/* Rules for using icons as black on a light background. */
/* Rules for using icons as white on a dark background. */
#breadcrumbs {
  font-size: 0.85714286em;
}
@media screen and (min-width: 801px) {
  #breadcrumbs {
    margin: 7px 0 0 14px;
  }
}
@media screen and (max-width: 800px) {
  #breadcrumbs {
    margin-top: 0.5em;
    line-height: 1.25em;
  }
}
#breadcrumbs .iamhere_link::after {
  content: ">";
}
#breadcrumbs .iamhere_link a {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-decoration: none;
}
@media all {
  .subcolumns {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .subcolumns_oldgecko {
    width: 100%;
    float: left;
  }
  .subc {
    padding: 0 0.5em;
  }
  .subcl {
    padding: 0 1em 0 0;
  }
  .subcr {
    padding: 0 0 0 1em;
  }
  .col_w5l {
    float: left;
    width: 5%;
    display: inline;
  }
  .col_w5r {
    float: right;
    width: 5%;
    display: inline;
  }
  .col_w10l {
    float: left;
    width: 10%;
    display: inline;
  }
  .col_w10r {
    float: right;
    width: 10%;
    display: inline;
  }
  .col_w13l {
    float: left;
    width: 13%;
    display: inline;
  }
  .col_w13r {
    float: right;
    width: 13%;
    display: inline;
  }
  .col_w15l {
    float: left;
    width: 15%;
    display: inline;
  }
  .col_w15r {
    float: right;
    width: 15%;
    display: inline;
  }
  .col_w20l {
    float: left;
    width: 20%;
    display: inline;
  }
  .col_w20r {
    float: right;
    width: 20%;
    display: inline;
  }
  .col_w23l {
    float: left;
    width: 23%;
    display: inline;
  }
  .col_w23r {
    float: right;
    width: 23%;
    display: inline;
  }
  .col_w25l {
    float: left;
    width: 25%;
    display: inline;
  }
  .col_w25r {
    float: right;
    width: 25%;
    display: inline;
  }
  .col_w30l {
    float: left;
    width: 30%;
    display: inline;
  }
  .col_w30r {
    float: right;
    width: 30%;
    display: inline;
  }
  .col_w33l {
    float: left;
    width: 33.333%;
    display: inline;
  }
  .col_w33r {
    float: right;
    width: 33.333%;
    display: inline;
  }
  .col_w34l {
    float: left;
    width: 34%;
    display: inline;
  }
  .col_w34r {
    float: right;
    width: 34%;
    display: inline;
  }
  .col_w38l {
    float: left;
    width: 38%;
    display: inline;
  }
  .col_w38r {
    float: right;
    width: 38%;
    display: inline;
  }
  .col_w40l {
    float: left;
    width: 40%;
    display: inline;
  }
  .col_w40r {
    float: right;
    width: 40%;
    display: inline;
  }
  .col_w50l {
    float: left;
    width: 50%;
    display: inline;
  }
  .col_w50r {
    float: right;
    width: 50%;
    display: inline;
  }
  .col_w55l {
    float: left;
    width: 55%;
    display: inline;
  }
  .col_w55r {
    float: right;
    width: 55%;
    display: inline;
  }
  .col_w60l {
    float: left;
    width: 60%;
    display: inline;
  }
  .col_w60r {
    float: right;
    width: 60%;
    display: inline;
  }
  .col_w62l {
    float: left;
    width: 62%;
    display: inline;
  }
  .col_w62r {
    float: right;
    width: 62%;
    display: inline;
  }
  .col_w66l {
    float: left;
    width: 66.666%;
    display: inline;
  }
  .col_w66r {
    float: right;
    width: 66.666%;
    display: inline;
  }
  .col_w70l {
    float: left;
    width: 70%;
    display: inline;
  }
  .col_w70r {
    float: right;
    width: 70%;
    display: inline;
  }
  .col_w72l {
    float: left;
    width: 72%;
    display: inline;
  }
  .col_w72r {
    float: right;
    width: 72%;
    display: inline;
  }
  .col_w75l {
    float: left;
    width: 75%;
    display: inline;
  }
  .col_w75r {
    float: right;
    width: 75%;
    display: inline;
  }
  .col_w77l {
    float: left;
    width: 77%;
    display: inline;
  }
  .col_w77r {
    float: right;
    width: 77%;
    display: inline;
  }
  .col_w80l {
    float: left;
    width: 80%;
    display: inline;
  }
  .col_w80r {
    float: right;
    width: 80%;
    display: inline;
  }
  .col_w85l {
    float: left;
    width: 85%;
    display: inline;
  }
  .col_w85r {
    float: right;
    width: 85%;
    display: inline;
  }
  .col_w87l {
    float: left;
    width: 87%;
    display: inline;
  }
  .col_w87r {
    float: right;
    width: 87%;
    display: inline;
  }
  .col_w90l {
    float: left;
    width: 90%;
    display: inline;
  }
  .col_w90r {
    float: right;
    width: 90%;
    display: inline;
  }
  .col_w95l {
    float: left;
    width: 95%;
    display: inline;
  }
  .col_w95r {
    float: right;
    width: 95%;
    display: inline;
  }
  .col_wal {
    float: left;
    display: inline;
  }
  .col_war {
    float: right;
    display: inline;
  }
  .syncheight {
    table-layout: fixed;
  }
  .syncheight .subcolumns {
    table-layout: fixed;
  }
  .syncheight > div {
    display: table-cell;
    float: none;
    margin: 0;
    overflow: hidden;
    vertical-align: top;
  }
}
#col3_content p,
#content p,
#tinymce p {
  line-height: 1.5em;
  margin-bottom: 1em;
}
#col3_content b,
#content b,
#tinymce b,
#col3_content strong,
#content strong,
#tinymce strong {
  font-weight: bold;
}
#col3_content hr,
#content hr,
#tinymce hr {
  border: 0 none;
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #ccc;
  background-color: #ccc;
  height: 1px;
}
#col3_content pre,
#content pre,
#tinymce pre {
  font-style: italic;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-left: 1em;
  border: 0 none;
  border-radius: 0;
  border-left: 0.75em solid #8a0f14;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #3d3d3d;
  background-color: unset;
  display: block;
  white-space: normal;
  margin-bottom: 1.5em;
  line-height: 1.5em;
  word-wrap: break-word;
  word-break: break-word;
}
#col3_content span .discreet,
#content span .discreet,
#tinymce span .discreet {
  color: #3d3d3d;
  font-size: 1em;
  font-weight: normal;
}
#col3_content .description,
#content .description,
#tinymce .description,
#col3_content .documentDescription,
#content .documentDescription,
#tinymce .documentDescription {
  margin: 1em 0 1.5em;
  font-weight: bolder;
}
#col3_content .discreet,
#content .discreet,
#tinymce .discreet {
  color: #3d3d3d;
  font-size: 1em;
}
@media screen and (min-width: 801px) {
  #col3_content .leadImage,
  #content .leadImage,
  #tinymce .leadImage {
    float: right;
    text-align: left;
  }
}
.portaltype-easyform #col3_content #content-core .form p.discreet {
  color: #3d3d3d;
  font-weight: bolder;
  margin: 1em 0 1.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field:hover {
  background-color: #e6e6e6;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .fieldErrorBox {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-indent: 1em;
  background-color: #f55;
  color: #fff;
  font-weight: bold;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option .bool-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option .tuple-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option .list-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option .checkbox-widget {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1em;
  order: 1;
  display: inline-block;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input[type="checkbox"] {
  order: 1;
  margin-right: 0;
}
@media screen and (min-width: 801px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input[type="checkbox"] {
    width: 5%;
  }
}
@media screen and (max-width: 800px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input[type="checkbox"] {
    width: 2.5em;
  }
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > label.horizontal {
  order: 2;
}
@media screen and (min-width: 801px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > label.horizontal {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > label.horizontal {
    width: 80%;
  }
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > label.horizontal > span {
  order: 2;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > label.horizontal .formHelp {
  margin: 0.25em 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
  order: 2;
}
@media screen and (min-width: 801px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 80%;
  }
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > div {
  order: 3;
}
@media screen and (min-width: 801px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > div {
    margin-left: 5%;
  }
}
@media screen and (max-width: 800px) {
  .portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option > div {
    margin-left: 2.5em;
  }
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option label {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  order: 2;
  display: inline-block;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option label .label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .option label input.radio-widget.required::after {
  display: none;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .textline-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .asciiline-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .int-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .email-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .uri-field {
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .textline-field:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .asciiline-field:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .int-field:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .email-field:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .uri-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .text-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .textarea-widget,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .json-widget {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .text-field:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .textarea-widget:focus-visible,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .json-widget:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .choice-field {
  padding: 0.25em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .tuple-field {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .tuple-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .tuple-field.checkbox-widget {
  height: auto;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .tuple-field.select2-orderable {
  height: auto;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .tuple-field.select2-orderable .select2-choices {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: transparent;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls.pull-right {
  float: none !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.pull-right {
  float: none !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.open .dropdown-menu {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  min-width: fit-content;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  list-style-type: none;
  list-style-position: inside;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li {
  list-style-type: none;
  list-style-position: inside;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li a {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.2em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper > *:not(:first-child) {
  margin-left: 1em !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 40%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 40%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap {
  padding: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: unset;
  position: unset;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--year {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 1;
  height: 2em;
  width: 20%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--month {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 2;
  height: 2em;
  width: 35%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--prev {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: -1;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--next {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: 100;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__day {
  padding: 0.25em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__weekday {
  padding: 0.25em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table td {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 20%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 20%;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #3d3d3d;
  padding: 0.5em 0.75em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
  color: #3d3d3d !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item--selected {
  color: #fff;
  background-color: #069;
  font-weight: 600;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-now {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-clear {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field {
  margin-bottom: 0 !important;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field .list-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field button[name="from2toButton"],
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field button[name="to2fromButton"],
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field button[name="upButton"],
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .ordered-selection-field button[name="downButton"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal {
  background-color: #fff;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType {
  padding: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkLabel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bolder;
  display: block;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group label {
  color: #707070;
  font-weight: normal;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field .multi-widget-field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="riedit"],
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="ridelete"] {
  line-height: 1.5em;
  padding: 0.25em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences {
  margin-top: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence {
  border: 0 none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence:hover {
  border: 0 none;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .batching {
  font-size: 1em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field label {
  font-weight: 600;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field label,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field label .label {
  color: #3d3d3d;
  line-height: 1.125em;
  font-size: 14px;
  white-space: inherit;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field label .required::after {
  color: #8a0f14;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 20px;
  width: 20px;
  content: 'info' !important;
  vertical-align: text-bottom;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .field label .formHelp {
  font-weight: 400;
  font-style: italic;
  display: block;
  color: #707070;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .formControls {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .formControls .button-field,
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .formControls button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .formControls .button-field:not(:last-of-type),
.portaltype-easyform #col3_content #content-core .form form.easyformForm.rowlike .formControls button:not(:last-of-type) {
  margin-right: 0.5em;
}
.portaltype-easyform #col3_content #content-core .form form.easyformForm #formEpilogue {
  margin-top: 1em;
}
#col3_content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon,
#content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon {
  background-image: none !important;
  height: auto;
  width: auto;
}
#col3_content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon.material-icons.acc-active,
#content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon.material-icons.acc-active,
#col3_content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon.material-icons.acc-closed,
#content .faq h3.ui-accordion-header.ui-accordion-icons .ui-accordion-header-icon.ui-icon.material-icons.acc-closed {
  text-indent: 0;
  position: relative;
  left: 0;
  top: 0;
  margin-top: 0;
  margin-right: 0.25em;
  vertical-align: middle;
}
#footer {
  font-size: 0.85714286em;
  margin-top: 1em;
  border-top: 1px solid #dcdcdc;
}
#footer .subcolumns {
  padding: 0.3em 0 0.15em 0;
  line-height: initial;
}
#footer .subcolumns .cl_200 {
  float: left;
  text-align: left;
}
@media screen and (min-width: 801px) {
  #footer .subcolumns .cl_200 {
    width: 200px;
  }
}
@media screen and (max-width: 800px) {
  #footer .subcolumns .cl_200 {
    width: 50%;
  }
}
#footer .subcolumns .cr_200 {
  float: right;
  text-align: right;
}
@media screen and (min-width: 801px) {
  #footer .subcolumns .cr_200 {
    width: 200px;
  }
}
#footer .subcolumns .cr_250 {
  float: right;
  text-align: right;
  width: 250px;
}
@media screen and (min-width: 801px) {
  #footer .subcolumns .c_auto {
    margin: 0 250px 0 200px;
  }
}
@media screen and (max-width: 800px) {
  #footer .subcolumns .c_auto {
    float: right;
    text-align: right;
    width: 50%;
  }
}
#footer .subcolumns .subcl {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
#footer .subcolumns .subcl span {
  margin-right: 0.2em;
}
#footer .subcolumns .subc {
  padding-left: 1em;
  padding-right: 0;
}
#footer .subcolumns .subc a {
  margin-left: 0.5em;
  margin-right: 0.5em;
  text-decoration: none;
  vertical-align: 0;
}
#footer .subcolumns .subc a:first-child {
  margin-left: 0;
}
#footer .subcolumns .subc a:active,
#footer .subcolumns .subc a:hover,
#footer .subcolumns .subc a:focus {
  color: #3d3d3d;
}
#footer .subcolumns .subcr {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
#footer .subcolumns .subcr a {
  margin-left: 0.2em;
  margin-right: 0.2em;
}
#footer .subcolumns .subcr a img {
  vertical-align: -0.3em;
}
#footer .subcolumns a {
  text-decoration: none;
}
#footer .subcolumns a:active,
#footer .subcolumns a:hover,
#footer .subcolumns afocus {
  color: #3d3d3d;
}
@media screen and (max-width: 800px) {
  #footer .subcolumns .nomobile {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
#footer #notice {
  line-height: 1.8em;
  background-color: #003d7f;
  color: #fff;
  padding: 0 12px;
}
@media screen and (max-width: 800px) {
  #footer #notice {
    text-align: center;
  }
}
#footer #notice a {
  text-decoration: none;
  color: #fff;
}
#footer #notice div {
  display: inline-block;
}
.template-folder_listing #col3_content #content-core .entries,
.template-listing_view #col3_content #content-core .entries {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.template-folder_listing #col3_content #content-core .entries .entry:not(:first-child),
.template-listing_view #col3_content #content-core .entries .entry:not(:first-child) {
  margin-top: 1em;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary [class^="icon-"],
.template-listing_view #col3_content #content-core .entries .entry header .summary [class^="icon-"] {
  color: #069;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-folder::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-folder::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'folder' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-document::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-document::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'description' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-image::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-image::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'image' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-easyform::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-easyform::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'edit' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-hubub-feedaggregator-rss::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-hubub-feedaggregator-rss::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'rss_feed' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-hubub-feedaggregator-twitter::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-hubub-feedaggregator-twitter::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'comment' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-glossary::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-glossary::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'dashboard' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-collection::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-collection::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'list' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-link::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-link::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'link' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-news-item::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-news-item::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'notifications' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .icon-event::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .icon-event::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  font-size: 18px !important;
  content: 'event' !important;
  vertical-align: sub;
}
.template-folder_listing #col3_content #content-core .entries .entry header .summary .contenttype-hubub-feedaggregator-rss::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .contenttype-hubub-feedaggregator-rss::before,
.template-folder_listing #col3_content #content-core .entries .entry header .summary .contenttype-hubub-feedaggregator-twitter::before,
.template-listing_view #col3_content #content-core .entries .entry header .summary .contenttype-hubub-feedaggregator-twitter::before {
  content: '' !important;
}
.template-folder_listing #col3_content #content-core .entries .entry header .documentByLine,
.template-listing_view #col3_content #content-core .entries .entry header .documentByLine {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-folder_listing #col3_content #content-core .entries .entry .description,
.template-listing_view #col3_content #content-core .entries .entry .description {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0.5em;
  font-weight: normal;
}
.template-album_view #col3_content #content-core {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.template-album_view #col3_content #content-core .photoAlbumEntry {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid #ccc;
  width: 266px;
  min-height: 230px;
  background-color: #f4f4f4;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  padding: 0.25em;
}
.template-album_view #col3_content #content-core .photoAlbumEntry:hover {
  background-color: #ccc;
}
.template-album_view #col3_content #content-core .photoAlbumEntry a {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0.5em;
  overflow-wrap: anywhere;
  text-align: center;
}
.template-album_view #col3_content #content-core .photoAlbumEntry a:hover {
  background-color: #ccc;
}
.template-album_view #col3_content #content-core .photoAlbumEntry a .photoAlbumEntryWrapper {
  margin-bottom: 0.25em;
}
.template-event_listing #col3_content header nav .mode_selector {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
  list-style-position: inside;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past a {
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past a:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past a span {
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past a span::before {
  content: "\00ab ";
  margin-right: 0;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past a span:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past-active span {
  color: #ccc;
  font-weight: bold;
}
.template-event_listing #col3_content header nav .mode_selector .mode-past-active span::before {
  content: "\00ab ";
  margin-right: 0;
}
.template-event_listing #col3_content header nav .mode_selector .mode-ical a {
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-ical a::after {
  content: "\1F4BE";
  margin: 0 none;
}
.template-event_listing #col3_content header nav .mode_selector .mode-ical a:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-ical a span {
  margin-right: 0.5em;
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-ical a span:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future a {
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future a::after {
  content: " \00bb";
  margin-left: 0;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future a:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future a span {
  color: #069;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future a span:hover {
  color: #003d7f;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future-active span {
  color: #ccc;
  font-weight: bold;
}
.template-event_listing #col3_content header nav .mode_selector .mode-future-active span::after {
  content: " \00bb";
  margin-left: 0;
}
.template-event_listing #col3_content #content-core section article.vevent .event-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.template-event_listing #col3_content #content-core section article.vevent .event-container .cal_info {
  margin-left: 26%;
  margin-top: 0.75em;
  margin-bottom: 1.25em;
}
.template-event_listing #col3_content #content-core section article.vevent .event-container .cal_info .description {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-weight: normal;
}
.template-event_listing #col3_content #content-core section article.vevent .event-header {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-align-content: center;
  align-content: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.template-event_listing #col3_content #content-core section article.vevent .event-header .cal_date {
  width: 25%;
  margin-right: 1%    ;
}
.template-event_listing #col3_content #content-core section article.vevent .event-header .cal_date .cal_day::after {
  content: ".";
}
.template-event_listing #col3_content #content-core section article.vevent .event-header .cal_date .cal_year::after {
  content: ", ";
}
.template-event_listing #col3_content #content-core section article.vevent .event-header .cal_head .tileHeadline {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-tabular_view #col3_content #content-core .listing {
  width: 100%;
}
.template-tabular_view #col3_content #content-core .listing tbody tr.even {
  background-color: #e6e6e6;
}
.template-summary_view #col3_content #content-core .tileItem {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 0.5em;
}
.template-summary_view #col3_content #content-core .tileItem:first-of-type {
  margin-top: 0;
}
@media screen and (min-width: 801px) {
  .template-summary_view #col3_content #content-core .tileItem {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (max-width: 800px) {
  .template-summary_view #col3_content #content-core .tileItem {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media screen and (min-width: 801px) {
  .template-summary_view #col3_content #content-core .tileItem .dates-states {
    width: 15%;
  }
}
@media screen and (max-width: 800px) {
  .template-summary_view #col3_content #content-core .tileItem .dates-states {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.template-summary_view #col3_content #content-core .tileItem .dates-states > div {
  margin-right: 0.5em;
}
.template-summary_view #col3_content #content-core .tileItem .dates-states > div:last-of-type {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-summary_view #col3_content #content-core .tileItem .dates-states .expired-state {
  border-radius: 1em;
  font-size: 0.9em;
  margin-left: 1em;
  padding: 0 0.5em;
  text-decoration: none !important;
  border-radius: 0;
  background-color: #e53030 !important;
  color: #fff !important;
  font-weight: bolder;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1em !important;
}
@media screen and (min-width: 801px) {
  .template-summary_view #col3_content #content-core .tileItem .item {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .template-summary_view #col3_content #content-core .tileItem .item {
    width: 100%;
    margin-left: 1em;
  }
}
.template-summary_view #col3_content #content-core .tileItem .item .tileHeadline {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-event_listing #col3_content #content-core .pagination,
.template-folder_listing #col3_content #content-core .pagination,
.template-listing_view #col3_content #content-core .pagination,
.template-tabular_view #col3_content #content-core .pagination,
.template-full_view #col3_content #content-core .pagination,
.template-summary_view #col3_content #content-core .pagination {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
.template-event_listing #col3_content #content-core .pagination ul,
.template-folder_listing #col3_content #content-core .pagination ul,
.template-listing_view #col3_content #content-core .pagination ul,
.template-tabular_view #col3_content #content-core .pagination ul,
.template-full_view #col3_content #content-core .pagination ul,
.template-summary_view #col3_content #content-core .pagination ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
  list-style-position: inside;
}
.template-event_listing #col3_content #content-core .pagination ul li,
.template-folder_listing #col3_content #content-core .pagination ul li,
.template-listing_view #col3_content #content-core .pagination ul li,
.template-tabular_view #col3_content #content-core .pagination ul li,
.template-full_view #col3_content #content-core .pagination ul li,
.template-summary_view #col3_content #content-core .pagination ul li {
  list-style-type: none;
  list-style-position: inside;
  padding: 0.25em;
}
.template-event_listing #col3_content #content-core .pagination ul li a,
.template-folder_listing #col3_content #content-core .pagination ul li a,
.template-listing_view #col3_content #content-core .pagination ul li a,
.template-tabular_view #col3_content #content-core .pagination ul li a,
.template-full_view #col3_content #content-core .pagination ul li a,
.template-summary_view #col3_content #content-core .pagination ul li a {
  text-decoration: none;
  color: #069;
}
.template-event_listing #col3_content #content-core .pagination ul li a .label,
.template-folder_listing #col3_content #content-core .pagination ul li a .label,
.template-listing_view #col3_content #content-core .pagination ul li a .label,
.template-tabular_view #col3_content #content-core .pagination ul li a .label,
.template-full_view #col3_content #content-core .pagination ul li a .label,
.template-summary_view #col3_content #content-core .pagination ul li a .label,
.template-event_listing #col3_content #content-core .pagination ul li a .arrow,
.template-folder_listing #col3_content #content-core .pagination ul li a .arrow,
.template-listing_view #col3_content #content-core .pagination ul li a .arrow,
.template-tabular_view #col3_content #content-core .pagination ul li a .arrow,
.template-full_view #col3_content #content-core .pagination ul li a .arrow,
.template-summary_view #col3_content #content-core .pagination ul li a .arrow {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  color: #069;
}
.template-event_listing #col3_content #content-core .pagination ul li a:hover,
.template-folder_listing #col3_content #content-core .pagination ul li a:hover,
.template-listing_view #col3_content #content-core .pagination ul li a:hover,
.template-tabular_view #col3_content #content-core .pagination ul li a:hover,
.template-full_view #col3_content #content-core .pagination ul li a:hover,
.template-summary_view #col3_content #content-core .pagination ul li a:hover {
  text-decoration: underline;
  color: #003d7f;
}
.template-event_listing #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-folder_listing #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-listing_view #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-tabular_view #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-full_view #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-summary_view #col3_content #content-core .pagination ul li a:hover .label:hover,
.template-event_listing #col3_content #content-core .pagination ul li a:hover .arrow:hover,
.template-folder_listing #col3_content #content-core .pagination ul li a:hover .arrow:hover,
.template-listing_view #col3_content #content-core .pagination ul li a:hover .arrow:hover,
.template-tabular_view #col3_content #content-core .pagination ul li a:hover .arrow:hover,
.template-full_view #col3_content #content-core .pagination ul li a:hover .arrow:hover,
.template-summary_view #col3_content #content-core .pagination ul li a:hover .arrow:hover {
  color: #003d7f;
}
.template-event_listing #col3_content #content-core .pagination ul li.previous .label,
.template-folder_listing #col3_content #content-core .pagination ul li.previous .label,
.template-listing_view #col3_content #content-core .pagination ul li.previous .label,
.template-tabular_view #col3_content #content-core .pagination ul li.previous .label,
.template-full_view #col3_content #content-core .pagination ul li.previous .label,
.template-summary_view #col3_content #content-core .pagination ul li.previous .label {
  padding-right: 1em;
}
.template-event_listing #col3_content #content-core .pagination ul li.previous .arrow::before,
.template-folder_listing #col3_content #content-core .pagination ul li.previous .arrow::before,
.template-listing_view #col3_content #content-core .pagination ul li.previous .arrow::before,
.template-tabular_view #col3_content #content-core .pagination ul li.previous .arrow::before,
.template-full_view #col3_content #content-core .pagination ul li.previous .arrow::before,
.template-summary_view #col3_content #content-core .pagination ul li.previous .arrow::before {
  content: "\00ab";
  margin-right: 0;
}
.template-event_listing #col3_content #content-core .pagination ul li.active span,
.template-folder_listing #col3_content #content-core .pagination ul li.active span,
.template-listing_view #col3_content #content-core .pagination ul li.active span,
.template-tabular_view #col3_content #content-core .pagination ul li.active span,
.template-full_view #col3_content #content-core .pagination ul li.active span,
.template-summary_view #col3_content #content-core .pagination ul li.active span {
  font-size: 1.25em;
}
.template-event_listing #col3_content #content-core .pagination ul li.next .label,
.template-folder_listing #col3_content #content-core .pagination ul li.next .label,
.template-listing_view #col3_content #content-core .pagination ul li.next .label,
.template-tabular_view #col3_content #content-core .pagination ul li.next .label,
.template-full_view #col3_content #content-core .pagination ul li.next .label,
.template-summary_view #col3_content #content-core .pagination ul li.next .label {
  padding-left: 1em;
}
.template-event_listing #col3_content #content-core .pagination ul li.next .arrow::after,
.template-folder_listing #col3_content #content-core .pagination ul li.next .arrow::after,
.template-listing_view #col3_content #content-core .pagination ul li.next .arrow::after,
.template-tabular_view #col3_content #content-core .pagination ul li.next .arrow::after,
.template-full_view #col3_content #content-core .pagination ul li.next .arrow::after,
.template-summary_view #col3_content #content-core .pagination ul li.next .arrow::after {
  content: "\00bb";
  margin-left: 0;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .glossaryAbcedaire,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .glossaryAbcedaire,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .glossaryAbcedaire,
#visual-portal-wrapper.portaltype-glossary #content #glossary .glossaryAbcedaire {
  list-style-type: none;
  list-style-position: inside;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .glossaryAbcedaire li,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .glossaryAbcedaire li,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .glossaryAbcedaire li,
#visual-portal-wrapper.portaltype-glossary #content #glossary .glossaryAbcedaire li {
  list-style-type: none;
  list-style-position: inside;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .glossaryAbcedaire li a,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .glossaryAbcedaire li a,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .glossaryAbcedaire li a,
#visual-portal-wrapper.portaltype-glossary #content #glossary .glossaryAbcedaire li a {
  padding: 0 0.25em;
  text-decoration: none;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .glossaryAbcedaire li a.selected,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .glossaryAbcedaire li a.selected,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .glossaryAbcedaire li a.selected,
#visual-portal-wrapper.portaltype-glossary #content #glossary .glossaryAbcedaire li a.selected {
  color: #3d3d3d;
  text-decoration: none;
  font-size: 1.25em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .glossaryAbcedaire li a:hover,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .glossaryAbcedaire li a:hover,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .glossaryAbcedaire li a:hover,
#visual-portal-wrapper.portaltype-glossary #content #glossary .glossaryAbcedaire li a:hover {
  text-decoration: underline;
  color: #003d7f;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary #glossary_search,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary #glossary_search,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary #glossary_search,
#visual-portal-wrapper.portaltype-glossary #content #glossary #glossary_search {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 1em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary #glossary_search #search_text,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary #glossary_search #search_text,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary #glossary_search #search_text,
#visual-portal-wrapper.portaltype-glossary #content #glossary #glossary_search #search_text {
  float: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  padding: 0.25em 0.5em;
  width: 85%;
  height: 1.5em;
  border-right: 1px solid #ccc;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary #glossary_search #search_text:focus-visible,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary #glossary_search #search_text:focus-visible,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary #glossary_search #search_text:focus-visible,
#visual-portal-wrapper.portaltype-glossary #content #glossary #glossary_search #search_text:focus-visible {
  border-radius: 0;
  outline: 1px solid #003d7f;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary #glossary_search .searchButton,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary #glossary_search .searchButton,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary #glossary_search .searchButton,
#visual-portal-wrapper.portaltype-glossary #content #glossary #glossary_search .searchButton {
  float: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  border-radius: 0;
  width: 15%;
  padding: 0.25em 0.5em;
  color: #3d3d3d;
  background-color: #e6e6e6;
  font-weight: 600;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary #glossary_search .searchButton:hover,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary #glossary_search .searchButton:hover,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary #glossary_search .searchButton:hover,
#visual-portal-wrapper.portaltype-glossary #content #glossary #glossary_search .searchButton:hover {
  color: #000;
  background-color: #ccc;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
  list-style-position: inside;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li {
  list-style-type: none;
  list-style-position: inside;
  padding: 0.25em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a {
  text-decoration: none;
  color: #069;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a .label,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a .label,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a .label,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a .label,
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a .arrow,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a .arrow,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a .arrow,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a .arrow {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  color: #069;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a:hover,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a:hover,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a:hover,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a:hover {
  text-decoration: underline;
  color: #003d7f;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a:hover .label:hover,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a:hover .label:hover,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a:hover .label:hover,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a:hover .label:hover,
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li a:hover .arrow:hover,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li a:hover .arrow:hover,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li a:hover .arrow:hover,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li a:hover .arrow:hover {
  color: #003d7f;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li.previous .label,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li.previous .label,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li.previous .label,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li.previous .label {
  padding-right: 1em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li.previous .arrow::before,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li.previous .arrow::before,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li.previous .arrow::before,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li.previous .arrow::before {
  content: "\00ab";
  margin-right: 0;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li.active span,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li.active span,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li.active span,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li.active span {
  font-size: 1.25em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li.next .label,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li.next .label,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li.next .label,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li.next .label {
  padding-left: 1em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content #glossary .pagination ul li.next .arrow::after,
#visual-portal-wrapper.portaltype-glossary #col3_content #glossary .pagination ul li.next .arrow::after,
#visual-portal-wrapper.portaltype-glossaryterm #content #glossary .pagination ul li.next .arrow::after,
#visual-portal-wrapper.portaltype-glossary #content #glossary .pagination ul li.next .arrow::after {
  content: "\00bb";
  margin-left: 0;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content .termDescription,
#visual-portal-wrapper.portaltype-glossary #col3_content .termDescription,
#visual-portal-wrapper.portaltype-glossaryterm #content .termDescription,
#visual-portal-wrapper.portaltype-glossary #content .termDescription {
  border-bottom: 1px solid #ccc;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content .variants,
#visual-portal-wrapper.portaltype-glossary #col3_content .variants,
#visual-portal-wrapper.portaltype-glossaryterm #content .variants,
#visual-portal-wrapper.portaltype-glossary #content .variants {
  margin-top: 1em;
}
#visual-portal-wrapper.portaltype-glossaryterm #col3_content .variants label,
#visual-portal-wrapper.portaltype-glossary #col3_content .variants label,
#visual-portal-wrapper.portaltype-glossaryterm #content .variants label,
#visual-portal-wrapper.portaltype-glossary #content .variants label {
  color: #003d7f;
}
@media screen and (min-width: 801px) {
  #header {
    height: auto;
    padding-top: 0.25em;
  }
}
@media screen and (max-width: 800px) {
  #header {
    height: 110px;
    margin-bottom: 0.5em;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
}
@media screen and (max-width: 800px) {
  #header .col_w40l {
    float: none;
    width: auto;
  }
}
@media screen and (max-width: 800px) {
  #header .col_w60r {
    float: none;
    width: auto;
  }
}
@media screen and (min-width: 801px) {
  #header .subcl {
    padding-left: 0.75em;
    padding-top: 3em;
  }
}
@media screen and (max-width: 800px) {
  #header .subcl {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  #header .subcl img {
    width: 100%;
    min-height: 10px;
    height: 100%;
    max-height: 14px;
  }
}
@media screen and (min-width: 801px) {
  #header .subcr {
    text-align: right;
  }
}
@media screen and (max-width: 800px) {
  #header .subcr {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 800px) {
  #header .subcr img {
    width: 100%;
    max-width: 470px;
    height: 100%;
    max-height: 89px;
  }
}
@media screen and (min-width: 801px) {
  #site-head {
    display: inline-block;
  }
}
#col3_content h1,
#content h1,
#tinymce h1 {
  font-size: 1.5em;
  font-weight: bold;
  color: #069;
  /*#cbd9e6;*/
  line-height: 1.5em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-bottom: 0.2em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #ccc;
}
#col3_content h2,
#content h2,
#tinymce h2 {
  margin-bottom: 0.5em;
  margin-top: 2.5em;
  border-top: 1px solid #ccc;
  border-bottom: 0 none;
  font-size: 1.25em;
  line-height: 1.5em;
}
#col3_content h2,
#content h2,
#tinymce h2,
#col3_content h2 > a,
#content h2 > a,
#tinymce h2 > a {
  padding-top: 0.5em;
  font-weight: bolder;
  color: #8a0f14 !important;
}
#col3_content h2:first-of-type,
#content h2:first-of-type,
#tinymce h2:first-of-type {
  border: 0 none;
  margin-top: 0.5em;
}
#col3_content h3,
#content h3,
#tinymce h3 {
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}
#col3_content h3,
#content h3,
#tinymce h3,
#col3_content h3 > a,
#content h3 > a,
#tinymce h3 > a {
  font-size: 1em;
  font-weight: bolder;
  color: #003d7f !important;
}
#col3_content h4,
#content h4,
#tinymce h4 {
  margin-bottom: 0.5em;
  margin-top: 0.75em;
  line-height: 1.5em;
}
#col3_content h4,
#content h4,
#tinymce h4,
#col3_content h4 > a,
#content h4 > a,
#tinymce h4 > a {
  font-size: 1em;
  font-weight: bolder;
  color: #3d3d3d !important;
}
@media all {
  .icon-arr-db-north {
    content: 'keyboard_double_arrow_up' !important;
  }
  .icon-arr-db-south {
    content: 'keyboard_double_arrow_down' !important;
  }
  .icon-arr-north::after {
    content: 'expand_less' !important;
  }
  .icon-arr-south:after {
    content: 'expand_more' !important;
  }
  #col3_content .contenttype-topnews_item::before,
  #content-core .contenttype-topnews_item::before,
  #col3_content .contenttype-news_folder::before,
  #content-core .contenttype-news_folder::before,
  #col3_content .contenttype-news_department::before,
  #content-core .contenttype-news_department::before {
    vertical-align: top;
    margin-right: 0.2em;
  }
  #col3_content .mime-icon,
  #content-core .mime-icon {
    margin-right: 0.2em;
  }
}
#splash {
  position: relative;
}
@media screen and (max-width: 800px) {
  #splash {
    position: absolute;
    z-index: 10000;
    width: auto;
    bottom: -3em;
  }
}
#splash > .subcolumns {
  background-color: #003d7f;
  line-height: 1.5em;
  color: #fff;
}
@media screen and (min-width: 801px) {
  #splash > .subcolumns {
    position: absolute;
    top: 0px;
    left: 12.5%;
    width: 75%;
    border-radius: 0 0 1em 1em;
  }
}
@media screen and (max-width: 800px) {
  #splash > .subcolumns {
    width: calc(100vw - 10px);
    min-width: 310px;
    border-radius: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  #splash > .subcolumns .col_w80l {
    float: none;
    width: auto;
    padding: 0.25em 0.5em;
  }
  #splash > .subcolumns .col_w20r {
    float: none;
    width: auto;
    min-width: 6em;
    padding: 0.25em 0.5em;
  }
}
#splash a {
  color: #fff;
  text-decoration: none;
}
#splash a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  #splash .subcl {
    text-align: center;
    padding: 3px 0.5em 5px 5px;
  }
}
@media screen and (max-width: 800px) {
  #splash .subcl {
    text-align: left;
    padding: 0;
  }
}
#splash .subcr {
  text-align: right;
}
@media screen and (min-width: 801px) {
  #splash .subcr {
    padding: 3px 5px 5px 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #splash .subcr {
    padding: 0;
  }
}
@media all {
  #pagescroller_wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    display: inline-block;
    z-index: 10000;
    margin: 1em;
    display: none;
  }
  #pagescroller_container {
    z-index: 10000;
    font-size: 2em;
    background-color: rgba(61, 61, 61, 0.5);
    color: #fff;
    width: 1.25em;
    height: 1.25em;
    text-align: center;
    cursor: pointer;
  }
}
@media screen and (max-width: 800px) {
  #menuWrap .portletwrapper-mainnavigation .mainnavigation {
    margin-bottom: 0.25em;
  }
  #menuWrap .portletwrapper-mainnavigation .mainnavigation li.navTreeItem.mobile {
    background-color: #003d7f;
  }
  #menuWrap .portletwrapper-mainnavigation .mainnavigation li.navTreeItem.mobile .flexcol {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border: 0 none;
    padding: 0.5em 1em;
  }
  #menuWrap .portletwrapper-mainnavigation .mainnavigation li.navTreeItem.mobile .flexcol .langselector a {
    padding-left: 0.5em;
    border-left: 1px solid #fff;
  }
  #menuWrap .portletwrapper-mainnavigation .mainnavigation li.navTreeItem.mobile .flexcol .langselector a:first-of-type {
    border-left: 0 none;
    padding-right: 0.5em;
    padding-left: 0;
  }
  #menuWrap .portletwrapper-mainnavigation .mainnavigation li.navTreeItem.mobile a {
    border: 0 none;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  #col1 {
    margin-left: -207px;
    top: 2%;
    left: 0;
  }
  #col1_content {
    width: 200px;
    padding: 0;
    float: left;
    text-align: left;
    border-top: 2px solid rgba(63, 63, 63, 0.8);
    border-right: 2px solid rgba(63, 63, 63, 0.8);
    border-bottom: 2px solid rgba(63, 63, 63, 0.8);
  }
  #menuWrap {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  #menuBtCol {
    width: 40px;
    height: 50px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: right;
    text-align: right;
  }
  #menuBtWrap {
    font-size: 3em;
    background-color: rgba(63, 63, 63, 0.8);
    color: #e6e6e6;
    text-align: center;
    cursor: pointer;
    border-radius: 0 25px 25px 0;
    height: 100%;
  }
  #menuBtWrap a {
    color: #e6e6e6;
    vertical-align: text-bottom;
  }
  #menuBtWrap a:hover,
  #menuBtWrap a:focus,
  #menuBtWrap a:active {
    color: #e6e6e6;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
}
.template-www-registration-form .ui-datepicker.ui-widget {
  border: 1px solid #ccc;
  background-color: #fff;
}
.template-www-registration-form .ui-datepicker-prev {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 25px;
  width: 25px;
  color: #666;
}
.template-www-registration-form .ui-datepicker-prev::after {
  content: 'chevron_left' !important;
}
.template-www-registration-form .ui-datepicker-prev:focus,
.template-www-registration-form .ui-datepicker-prev:hover {
  border: 0 none;
  text-decoration: none;
  background-color: #666;
  color: #e6e6e6;
}
.template-www-registration-form .ui-datepicker-prev:hover {
  cursor: pointer;
}
.template-www-registration-form .ui-datepicker-prev .ui-icon {
  background-image: none !important;
}
.template-www-registration-form .ui-datepicker-next {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 25px;
  width: 25px;
}
.template-www-registration-form .ui-datepicker-next::after {
  content: 'chevron_right' !important;
}
.template-www-registration-form .ui-datepicker-next:focus,
.template-www-registration-form .ui-datepicker-next:hover {
  border: 0 none;
  text-decoration: none;
  background-color: #666;
  color: #e6e6e6;
}
.template-www-registration-form .ui-datepicker-next:hover {
  cursor: pointer;
}
.template-www-registration-form .ui-datepicker-next .ui-icon {
  background-image: none !important;
}
.template-www-registration-form .ui-datepicker-calendar td a {
  border: 1px solid #fff;
}
.template-www-registration-form .ui-datepicker-calendar td a:hover {
  border: 1px solid #3d3d3d;
  background-color: #666;
  color: #e6e6e6;
}
@media screen and (max-width: 800px) {
  #language {
    display: none;
  }
}
#language #portal-languageselector {
  list-style-type: none;
  list-style-position: inside;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  background: none;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  line-height: 2.07142857em;
}
#language #portal-languageselector li.currentLanguage {
  font-weight: bold;
}
#language #portal-languageselector li a {
  color: #003d7f;
}
#language #portal-languageselector li a:hover {
  text-decoration: underline;
}
#language #portal-languageselector li a:visited {
  color: #003d7f;
}
#main {
  padding: 0;
}
@media screen and (min-width: 801px) {
  #main {
    margin-top: 67.4px;
  }
}
@media screen and (max-width: 800px) {
}
#col1_content {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
#col3_content .mosaic-grid {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 100%;
}
#col3_content .mosaic-grid .row {
  margin-left: 0;
  margin-right: 0;
}
#col3_content .mosaic-grid .row .mosaic-row-grid {
  padding-left: 0;
  padding-right: 0;
}
#col3_content .mosaic-grid .row .mosaic-row-grid #content {
  padding-left: 0;
  padding-right: 0;
}
a {
  color: #069;
}
a:active {
  border: 0 none;
  outline: none;
  outline-offset: 0;
}
a:hover {
  border: 0 none;
  outline: none;
  outline-offset: 0;
  color: #003d7f;
}
a:focus {
  border: 0 none;
  outline: none;
  outline-offset: 0;
}
#col3_content h2 > a,
#tinymce h2 > a,
#col3_content h3 > a,
#tinymce h3 > a,
#col3_content h4 > a,
#tinymce h4 > a {
  text-decoration: none !important;
}
#col3_content h2 > a[href]:hover,
#tinymce h2 > a[href]:hover,
#col3_content h3 > a[href]:hover,
#tinymce h3 > a[href]:hover,
#col3_content h4 > a[href]:hover,
#tinymce h4 > a[href]:hover {
  text-decoration: underline !important;
}
@media all {
  ol,
  ul {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  #col3_content ol,
  #content ol,
  #tinymce ol,
  #col3_content ul,
  #content ul,
  #tinymce ul {
    line-height: 1.5em;
    color: #3d3d3d;
  }
  p + #col3_content ol,
  p + #content ol,
  p + #tinymce ol,
  p + #col3_content ul,
  p + #content ul,
  p + #tinymce ul {
    margin-bottom: 1em;
  }
  #col3_content ol li,
  #content ol li,
  #tinymce ol li,
  #col3_content ul li,
  #content ul li,
  #tinymce ul li {
    margin: 0;
    line-height: 1.5em;
    text-indent: 0em;
  }
  #col3_content ol li::marker,
  #content ol li::marker,
  #tinymce ol li::marker,
  #col3_content ul li::marker,
  #content ul li::marker,
  #tinymce ul li::marker {
    color: #003d7f;
  }
  #col3_content ol,
  #content ol,
  #tinymce ol {
    margin-left: 1.8em;
  }
  #col3_content ol li.listTypeLowerAlpha,
  #content ol li.listTypeLowerAlpha,
  #tinymce ol li.listTypeLowerAlpha {
    list-style-type: lower-latin;
  }
  #col3_content ol li.listTypeUpperAlpha,
  #content ol li.listTypeUpperAlpha,
  #tinymce ol li.listTypeUpperAlpha {
    list-style-type: upper-latin;
  }
  #col3_content ol li.listTypeLowerRoman,
  #content ol li.listTypeLowerRoman,
  #tinymce ol li.listTypeLowerRoman {
    list-style-type: lower-roman;
  }
  #col3_content ol li.listTypeUpperRoman,
  #content ol li.listTypeUpperRoman,
  #tinymce ol li.listTypeUpperRoman {
    list-style-type: upper-roman;
  }
  #col3_content ul,
  #content ul,
  #tinymce ul {
    margin-left: 1.25em;
    list-style-type: square;
    list-style-position: outside;
  }
  #col3_content ul li,
  #content ul li,
  #tinymce ul li {
    color: #003d7f;
  }
  #col3_content ul li span,
  #content ul li span,
  #tinymce ul li span {
    color: #3d3d3d;
  }
}
.template-login #login-form form,
.template-login_form #login-form form {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.template-login #login-form form .field,
.template-login_form #login-form form .field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-login #login-form form .field:hover,
.template-login_form #login-form form .field:hover {
  background-color: #e6e6e6;
}
.template-login #login-form form .field .fieldErrorBox,
.template-login_form #login-form form .field .fieldErrorBox {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-indent: 1em;
  background-color: #f55;
  color: #fff;
  font-weight: bold;
}
.template-login #login-form form .field .option,
.template-login_form #login-form form .field .option {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.template-login #login-form form .field .option .bool-field,
.template-login_form #login-form form .field .option .bool-field,
.template-login #login-form form .field .option .tuple-field,
.template-login_form #login-form form .field .option .tuple-field,
.template-login #login-form form .field .option .list-field,
.template-login_form #login-form form .field .option .list-field,
.template-login #login-form form .field .option .checkbox-widget,
.template-login_form #login-form form .field .option .checkbox-widget {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1em;
  order: 1;
  display: inline-block;
}
.template-login #login-form form .field .option > input[type="checkbox"],
.template-login_form #login-form form .field .option > input[type="checkbox"] {
  order: 1;
  margin-right: 0;
}
@media screen and (min-width: 801px) {
  .template-login #login-form form .field .option > input[type="checkbox"],
  .template-login_form #login-form form .field .option > input[type="checkbox"] {
    width: 5%;
  }
}
@media screen and (max-width: 800px) {
  .template-login #login-form form .field .option > input[type="checkbox"],
  .template-login_form #login-form form .field .option > input[type="checkbox"] {
    width: 2.5em;
  }
}
.template-login #login-form form .field .option > label.horizontal,
.template-login_form #login-form form .field .option > label.horizontal {
  order: 2;
}
@media screen and (min-width: 801px) {
  .template-login #login-form form .field .option > label.horizontal,
  .template-login_form #login-form form .field .option > label.horizontal {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .template-login #login-form form .field .option > label.horizontal,
  .template-login_form #login-form form .field .option > label.horizontal {
    width: 80%;
  }
}
.template-login #login-form form .field .option > label.horizontal > span,
.template-login_form #login-form form .field .option > label.horizontal > span {
  order: 2;
}
.template-login #login-form form .field .option > label.horizontal .formHelp,
.template-login_form #login-form form .field .option > label.horizontal .formHelp {
  margin: 0.25em 0;
}
.template-login #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label,
.template-login_form #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
  order: 2;
}
@media screen and (min-width: 801px) {
  .template-login #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label,
  .template-login_form #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .template-login #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label,
  .template-login_form #login-form form .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 80%;
  }
}
.template-login #login-form form .field .option > div,
.template-login_form #login-form form .field .option > div {
  order: 3;
}
@media screen and (min-width: 801px) {
  .template-login #login-form form .field .option > div,
  .template-login_form #login-form form .field .option > div {
    margin-left: 5%;
  }
}
@media screen and (max-width: 800px) {
  .template-login #login-form form .field .option > div,
  .template-login_form #login-form form .field .option > div {
    margin-left: 2.5em;
  }
}
.template-login #login-form form .field .option label,
.template-login_form #login-form form .field .option label {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  order: 2;
  display: inline-block;
}
.template-login #login-form form .field .option label .label,
.template-login_form #login-form form .field .option label .label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-login #login-form form .field .option label input.radio-widget.required::after,
.template-login_form #login-form form .field .option label input.radio-widget.required::after {
  display: none;
}
.template-login #login-form form .field .textline-field,
.template-login_form #login-form form .field .textline-field,
.template-login #login-form form .field .asciiline-field,
.template-login_form #login-form form .field .asciiline-field,
.template-login #login-form form .field .int-field,
.template-login_form #login-form form .field .int-field,
.template-login #login-form form .field .email-field,
.template-login_form #login-form form .field .email-field,
.template-login #login-form form .field .uri-field,
.template-login_form #login-form form .field .uri-field {
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-login #login-form form .field .textline-field:focus-visible,
.template-login_form #login-form form .field .textline-field:focus-visible,
.template-login #login-form form .field .asciiline-field:focus-visible,
.template-login_form #login-form form .field .asciiline-field:focus-visible,
.template-login #login-form form .field .int-field:focus-visible,
.template-login_form #login-form form .field .int-field:focus-visible,
.template-login #login-form form .field .email-field:focus-visible,
.template-login_form #login-form form .field .email-field:focus-visible,
.template-login #login-form form .field .uri-field:focus-visible,
.template-login_form #login-form form .field .uri-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-login #login-form form .field .text-field,
.template-login_form #login-form form .field .text-field,
.template-login #login-form form .field .textarea-widget,
.template-login_form #login-form form .field .textarea-widget,
.template-login #login-form form .field .json-widget,
.template-login_form #login-form form .field .json-widget {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-login #login-form form .field .text-field:focus-visible,
.template-login_form #login-form form .field .text-field:focus-visible,
.template-login #login-form form .field .textarea-widget:focus-visible,
.template-login_form #login-form form .field .textarea-widget:focus-visible,
.template-login #login-form form .field .json-widget:focus-visible,
.template-login_form #login-form form .field .json-widget:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-login #login-form form .field .choice-field,
.template-login_form #login-form form .field .choice-field {
  padding: 0.25em;
}
.template-login #login-form form .field .tuple-field,
.template-login_form #login-form form .field .tuple-field {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-login #login-form form .field .tuple-field:focus-visible,
.template-login_form #login-form form .field .tuple-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-login #login-form form .field .tuple-field.checkbox-widget,
.template-login_form #login-form form .field .tuple-field.checkbox-widget {
  height: auto;
}
.template-login #login-form form .field .tuple-field.select2-orderable,
.template-login_form #login-form form .field .tuple-field.select2-orderable {
  height: auto;
}
.template-login #login-form form .field .tuple-field.select2-orderable .select2-choices,
.template-login_form #login-form form .field .tuple-field.select2-orderable .select2-choices {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: transparent;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls.pull-right,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls.pull-right {
  float: none !important;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown.pull-right,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown.pull-right {
  float: none !important;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown.open .dropdown-menu,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown.open .dropdown-menu {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  min-width: fit-content;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  list-style-type: none;
  list-style-position: inside;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li {
  list-style-type: none;
  list-style-position: inside;
}
.template-login #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li a,
.template-login_form #login-form form .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li a {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.2em;
}
.template-login #login-form form .field .pattern-pickadate-wrapper,
.template-login_form #login-form form .field .pattern-pickadate-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.template-login #login-form form .field .pattern-pickadate-wrapper > *:not(:first-child),
.template-login_form #login-form form .field .pattern-pickadate-wrapper > *:not(:first-child) {
  margin-left: 1em !important;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 40%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 40%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap {
  padding: 0.5em;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: unset;
  position: unset;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--year,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--year {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 1;
  height: 2em;
  width: 20%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--month,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--month {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 2;
  height: 2em;
  width: 35%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--prev,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--prev {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: -1;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--next,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--next {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: 100;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__day,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__day {
  padding: 0.25em;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__weekday,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__weekday {
  padding: 0.25em;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table td,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table td {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 20%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 20%;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #3d3d3d;
  padding: 0.5em 0.75em;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item:hover,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
  color: #3d3d3d !important;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item--selected,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item--selected {
  color: #fff;
  background-color: #069;
  font-weight: 600;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-now,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-now {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-login #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-clear,
.template-login_form #login-form form .field .pattern-pickadate-wrapper .pattern-pickadate-clear {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-login #login-form form .field .ordered-selection-field,
.template-login_form #login-form form .field .ordered-selection-field {
  margin-bottom: 0 !important;
}
.template-login #login-form form .field .ordered-selection-field .list-field,
.template-login_form #login-form form .field .ordered-selection-field .list-field,
.template-login #login-form form .field .ordered-selection-field button[name="from2toButton"],
.template-login_form #login-form form .field .ordered-selection-field button[name="from2toButton"],
.template-login #login-form form .field .ordered-selection-field button[name="to2fromButton"],
.template-login_form #login-form form .field .ordered-selection-field button[name="to2fromButton"],
.template-login #login-form form .field .ordered-selection-field button[name="upButton"],
.template-login_form #login-form form .field .ordered-selection-field button[name="upButton"],
.template-login #login-form form .field .ordered-selection-field button[name="downButton"],
.template-login_form #login-form form .field .ordered-selection-field button[name="downButton"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal {
  background-color: #fff;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType {
  padding: 0.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"],
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"]:focus-visible,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"],
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"]:focus-visible,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkLabel,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkLabel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bolder;
  display: block;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group label,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group label {
  color: #707070;
  font-weight: normal;
}
.template-login #login-form form .field .multi-widget-field,
.template-login_form #login-form form .field .multi-widget-field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="riedit"],
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="riedit"],
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="ridelete"],
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="ridelete"] {
  line-height: 1.5em;
  padding: 0.25em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences {
  margin-top: 0.5em;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence {
  border: 0 none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence:hover,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence:hover {
  border: 0 none;
}
.template-login #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .batching,
.template-login_form #login-form form .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .batching {
  font-size: 1em;
}
.template-login #login-form form .field label,
.template-login_form #login-form form .field label {
  font-weight: 600;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-login #login-form form .field label,
.template-login_form #login-form form .field label,
.template-login #login-form form .field label .label,
.template-login_form #login-form form .field label .label {
  color: #3d3d3d;
  line-height: 1.125em;
  font-size: 14px;
  white-space: inherit;
}
.template-login #login-form form .field label .required::after,
.template-login_form #login-form form .field label .required::after {
  color: #8a0f14;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 20px;
  width: 20px;
  content: 'info' !important;
  vertical-align: text-bottom;
}
.template-login #login-form form .field label .formHelp,
.template-login_form #login-form form .field label .formHelp {
  font-weight: 400;
  font-style: italic;
  display: block;
  color: #707070;
}
.template-login #login-form form .formControls,
.template-login_form #login-form form .formControls {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-login #login-form form .formControls .button-field,
.template-login_form #login-form form .formControls .button-field,
.template-login #login-form form .formControls button,
.template-login_form #login-form form .formControls button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-login #login-form form .formControls .button-field:not(:last-of-type),
.template-login_form #login-form form .formControls .button-field:not(:last-of-type),
.template-login #login-form form .formControls button:not(:last-of-type),
.template-login_form #login-form form .formControls button:not(:last-of-type) {
  margin-right: 0.5em;
}
.template-login #login-form form .field.error > div:first-of-type,
.template-login_form #login-form form .field.error > div:first-of-type {
  background-color: #f55;
  padding: 0.25em;
  width: fit-content;
  margin: 0.5em 0;
}
.template-login #login-form form .field.error > div:first-of-type .error,
.template-login_form #login-form form .field.error > div:first-of-type .error {
  color: #fff;
  font-weight: bold;
}
.template-login #login-form form .formControls,
.template-login_form #login-form form .formControls {
  padding: 0.5em 1em;
}
.template-login #login-form .footer,
.template-login_form #login-form .footer {
  padding-left: 1em;
}
body {
  line-height: 1.5em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  color: #3d3d3d;
}
* {
  box-sizing: content-box;
}
@media all {
  * {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  * html {
    /* avoid growing widths */
  }
  * html .subc,
  * html .subcl,
  * html .subcr {
    word-wrap: break-word;
    o\verflow: hidden;
  }
  * html body {
    overflow: visible;
    text-align: left;
  }
  div:target {
    outline: none;
  }
  [hidden] {
    display: none;
  }
  fieldset,
  img {
    border: 0 none;
  }
  .floatreset {
    display: block;
  }
  .floatreset::before {
    content: "";
    display: table;
  }
  .floatreset::after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
  }
  #ie_clearing {
    display: none;
  }
  .hide,
  .print,
  .hiddenStructure {
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
  .re-hiddenlink:focus,
  .re-hiddenlink:active {
    position: static;
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 801px) {
  body {
    overflow-y: scroll;
    background: #dcdcdc;
  }
}
@media screen and (min-width: 801px) {
  .page_margins {
    width: 1085px;
    background: #fff;
    margin: 0 auto;
  }
}
@media screen and (max-width: 800px) {
  .page_margins {
    width: 100%;
    min-width: 320px;
  }
}
@media screen and (min-width: 801px) {
  .page {
    padding: 0 10px 0 10px;
  }
}
@media screen and (max-width: 800px) {
  .page {
    padding: 0.5em 5px 0 5px;
    position: relative;
  }
}
@media screen and (min-width: 801px) {
  #col1_content,
  #col2_content,
  #col3_content {
    position: relative;
  }
  #col3_content {
    padding: 1.05em 0.3em 0 1em;
    /*depends on font-size*/
    z-index: 0;
  }
  #col1 {
    float: left;
    width: 200px;
  }
  #col3 {
    width: auto;
    margin: 0 0 0 200px;
  }
}
@media screen and (max-width: 800px) {
  #col1_content {
    background-color: rgba(63, 63, 63, 0.8);
    height: 80vh;
    overflow: scroll;
  }
  #col1 {
    position: fixed;
    z-index: 10001;
    float: none;
    width: 242px;
  }
  #col3_content {
    padding: 1.05em 0 0 0;
  }
  #col3 {
    margin: 0;
    width: 100%;
  }
}
#col3_content .mosaic-grid-row,
#content .mosaic-grid-row {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
}
@media screen and (min-width: 801px) {
  #col3_content .mosaic-grid-row,
  #content .mosaic-grid-row {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 800px) {
  #col3_content .mosaic-grid-row,
  #content .mosaic-grid-row {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#col3_content .mosaic-grid-row::before,
#content .mosaic-grid-row::before {
  content: unset;
  display: inline;
}
#col3_content .mosaic-grid-row::after,
#content .mosaic-grid-row::after {
  content: unset;
  display: inline;
}
#col3_content .mosaic-grid-cell,
#content .mosaic-grid-cell {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media screen and (min-width: 801px) {
  #col3_content .mosaic-grid-cell,
  #content .mosaic-grid-cell {
    padding: 0 0.75em;
  }
}
#col3_content .mosaic-grid-cell:first-of-type,
#content .mosaic-grid-cell:first-of-type {
  padding-left: 0;
}
#col3_content .mosaic-grid-cell:last-of-type,
#content .mosaic-grid-cell:last-of-type {
  padding-right: 0;
}
#col3_content .mosaic-width-full,
#content .mosaic-width-full {
  float: none;
  width: 100%;
  min-height: unset;
}
#col3_content .mosaic-width-full .mosaic-tile,
#content .mosaic-width-full .mosaic-tile {
  width: 100%;
}
#col3_content .mosaic-width-half,
#content .mosaic-width-half {
  float: none;
}
@media screen and (min-width: 801px) {
  #col3_content .mosaic-width-half,
  #content .mosaic-width-half {
    width: 50%;
  }
}
@media screen and (max-width: 800px) {
  #col3_content .mosaic-width-half,
  #content .mosaic-width-half {
    width: 100%;
  }
}
#col3_content .mosaic-width-third,
#content .mosaic-width-third {
  float: none;
  width: 33.33%;
}
#col3_content .mosaic-width-quarter,
#content .mosaic-width-quarter {
  float: none;
  width: 25%;
}
#col3_content .mosaic-position-leftmost,
#content .mosaic-position-leftmost {
  order: 1;
}
#col3_content .mosaic-position-quarter,
#content .mosaic-position-quarter {
  order: 3;
}
#col3_content .mosaic-position-third,
#content .mosaic-position-third {
  order: 6;
}
#col3_content .mosaic-position-half,
#content .mosaic-position-half {
  order: 9;
}
#col3_content .mosaic-position-two-thirds,
#content .mosaic-position-two-thirds {
  order: 12;
}
#col3_content .mosaic-position-three-quarters,
#content .mosaic-position-three-quarters {
  order: 15;
}
#col3_content .mosaic-tile .documentFirstHeading,
#content .mosaic-tile .documentFirstHeading,
#col3_content .mosaic-tile .documentDescription,
#content .mosaic-tile .documentDescription {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
#col3_content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile,
#content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile {
  width: 100%;
}
#col3_content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile a,
#content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile a {
  text-decoration: none;
}
#col3_content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile a:hover,
#content .mosaic-tile.mosaic-plone\.app\.standardtiles\.html-tile a:hover {
  text-decoration: underline;
}
#col3_content .mosaic-IDublinCore-title-tile,
#content .mosaic-IDublinCore-title-tile {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-bottom: 0.2em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #ccc;
}
#col3_content .mosaic-IDublinCore-title-tile .documentFirstHeading,
#content .mosaic-IDublinCore-title-tile .documentFirstHeading {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  font-size: 1.5em;
  font-weight: bold;
  color: #069;
  /*#cbd9e6;*/
  line-height: 1.5em;
}
#col3_content .mosaic-plone\.app\.standardtiles\.document_byline-tile,
#content .mosaic-plone\.app\.standardtiles\.document_byline-tile {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0.5em;
}
#col3_content .mosaic-plone\.app\.standardtiles\.document_byline-tile .documentByLine,
#content .mosaic-plone\.app\.standardtiles\.document_byline-tile .documentByLine {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
#col3_content .mosaic-IDublinCore-description-tile,
#content .mosaic-IDublinCore-description-tile {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0.5em;
}
#col3_content .mosaic-IDublinCore-description-tile .documentDescription,
#content .mosaic-IDublinCore-description-tile .documentDescription {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
@media screen and (max-width: 800px) {
  #col3_content .leadImage figure,
  #content .leadImage figure {
    width: 100%;
    display: block;
  }
}
#col3_content .leadImage figure a,
#content .leadImage figure a {
  text-decoration: none;
}
#col3_content .leadImage figure a:hover,
#content .leadImage figure a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  #col3_content .leadImage figure a,
  #content .leadImage figure a {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #col3_content .leadImage figure a img,
  #content .leadImage figure a img {
    height: 100%;
    width: 100%;
  }
}
#col3_content .leadImage figure a figcaption,
#content .leadImage figure a figcaption {
  background-color: #e6e6e6;
  margin-top: 0.25em;
  padding: 0.25em;
  color: #3d3d3d;
}
@media screen and (max-width: 800px) {
  #col3_content .leadImage figure a figcaption,
  #content .leadImage figure a figcaption {
    margin-bottom: 0.5em;
  }
}
#col3_content .news-dates span,
#content .news-dates span {
  font-size: 0.85714286em;
}
.plone-modal-wrapper.image-modal .plone-modal-dialog {
  width: min-content;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage {
  border: 1px solid #f55;
  background-color: #e6e6e6;
  color: #3d3d3d;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.info {
  border: 1px solid #5f5;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.info strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.info dt {
  background-color: #5f5;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.info strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.info dt::before {
  content: 'announcement' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.warning {
  border: 1px solid #ff9;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.warning strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.warning dt {
  background-color: #ff9;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.warning strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.warning dt::before {
  content: 'warning' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.error {
  background-color: #f55;
  color: #ededed;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.error strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage.error dt::before {
  content: 'error' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage dt {
  margin-right: 0.5em;
  height: 100%;
  float: left;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage strong,
  .plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage dt {
    width: 20%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage strong,
  .plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage dt {
    min-width: 8.5em;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body .portalMessage dt::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-right: 0.25em;
  vertical-align: middle;
  display: inline;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage {
  border: 1px solid #f55;
  background-color: #e6e6e6;
  color: #3d3d3d;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.info {
  border: 1px solid #5f5;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.info strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.info dt {
  background-color: #5f5;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.info strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.info dt::before {
  content: 'announcement' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.warning {
  border: 1px solid #ff9;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.warning strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.warning dt {
  background-color: #ff9;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.warning strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.warning dt::before {
  content: 'warning' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.error {
  background-color: #f55;
  color: #ededed;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.error strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage.error dt::before {
  content: 'error' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage strong,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage dt {
  margin-right: 0.5em;
  height: 100%;
  float: left;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage strong,
  .plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage dt {
    width: 20%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage strong,
  .plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage dt {
    min-width: 8.5em;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage strong::before,
.plone-modal-dialog .plone-modal-content .plone-modal-body #global_statusmessage .portalMessage dt::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-right: 0.25em;
  vertical-align: middle;
  display: inline;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body #content-core,
.plone-modal-dialog .plone-modal-content .plone-modal-body #layout-contents {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  line-height: 0em, 8333;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field:hover {
  background-color: #e6e6e6;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .fieldErrorBox {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-indent: 1em;
  background-color: #f55;
  color: #fff;
  font-weight: bold;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option .bool-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option .tuple-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option .list-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option .checkbox-widget {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1em;
  order: 1;
  display: inline-block;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input[type="checkbox"] {
  order: 1;
  margin-right: 0;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input[type="checkbox"] {
    width: 5%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input[type="checkbox"] {
    width: 2.5em;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > label.horizontal {
  order: 2;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > label.horizontal {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > label.horizontal {
    width: 80%;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > label.horizontal > span {
  order: 2;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > label.horizontal .formHelp {
  margin: 0.25em 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
  order: 2;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 80%;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > div {
  order: 3;
}
@media screen and (min-width: 801px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > div {
    margin-left: 5%;
  }
}
@media screen and (max-width: 800px) {
  .plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option > div {
    margin-left: 2.5em;
  }
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option label {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  order: 2;
  display: inline-block;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option label .label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .option label input.radio-widget.required::after {
  display: none;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .textline-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .asciiline-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .int-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .email-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .uri-field {
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .textline-field:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .asciiline-field:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .int-field:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .email-field:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .uri-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .text-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .textarea-widget,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .json-widget {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .text-field:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .textarea-widget:focus-visible,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .json-widget:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .choice-field {
  padding: 0.25em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .tuple-field {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .tuple-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .tuple-field.checkbox-widget {
  height: auto;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .tuple-field.select2-orderable {
  height: auto;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .tuple-field.select2-orderable .select2-choices {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: transparent;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls.pull-right {
  float: none !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.pull-right {
  float: none !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.open .dropdown-menu {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  min-width: fit-content;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  list-style-type: none;
  list-style-position: inside;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li {
  list-style-type: none;
  list-style-position: inside;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li a {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.2em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper > *:not(:first-child) {
  margin-left: 1em !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 40%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 40%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap {
  padding: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: unset;
  position: unset;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--year {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 1;
  height: 2em;
  width: 20%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--month {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 2;
  height: 2em;
  width: 35%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--prev {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: -1;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--next {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: 100;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__day {
  padding: 0.25em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__weekday {
  padding: 0.25em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table td {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 20%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 20%;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #3d3d3d;
  padding: 0.5em 0.75em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
  color: #3d3d3d !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item--selected {
  color: #fff;
  background-color: #069;
  font-weight: 600;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-now {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-clear {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field {
  margin-bottom: 0 !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field .list-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field button[name="from2toButton"],
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field button[name="to2fromButton"],
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field button[name="upButton"],
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .ordered-selection-field button[name="downButton"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal {
  background-color: #fff;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType {
  padding: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkLabel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bolder;
  display: block;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group label {
  color: #707070;
  font-weight: normal;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field .multi-widget-field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="riedit"],
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="ridelete"] {
  line-height: 1.5em;
  padding: 0.25em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences {
  margin-top: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence {
  border: 0 none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence:hover {
  border: 0 none;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .batching {
  font-size: 1em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field label {
  font-weight: 600;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field label,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field label .label {
  color: #3d3d3d;
  line-height: 1.125em;
  font-size: 14px;
  white-space: inherit;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field label .required::after {
  color: #8a0f14;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 20px;
  width: 20px;
  content: 'info' !important;
  vertical-align: text-bottom;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .field label .formHelp {
  font-weight: 400;
  font-style: italic;
  display: block;
  color: #707070;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .formControls {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .formControls .button-field,
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .formControls button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .formControls .button-field:not(:last-of-type),
.plone-modal-dialog .plone-modal-content .plone-modal-body form.rowlike .formControls button:not(:last-of-type) {
  margin-right: 0.5em;
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .select2-choices .select2-search-choice-close {
  background: 0 none;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 18px;
  width: 18px;
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.54);
}
.plone-modal-dialog .plone-modal-content .plone-modal-body .select2-choices .select2-search-choice-close::after {
  content: 'close' !important;
}
.plone-modal-dialog .plone-modal-content .plone-modal-footer .pattern-modal-buttons input[type="submit"] {
  padding: 0.25em;
  margin-left: 0.5em;
}
#content-core #searchform .actionMenu {
  margin-bottom: 1em;
}
#content-core #searchform .actionMenuHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: baseline;
  align-items: baseline;
  margin-bottom: 0.5em;
}
#content-core #searchform .actionMenuHeader #search-filter-toggle {
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 0.25em 0.5em;
  background-color: #e6e6e6;
}
#content-core #searchform .actionMenuHeader #search-filter-toggle:hover {
  color: #000;
  background-color: #ccc;
}
#content-core #searchform .actionMenuHeader .note {
  margin-left: 0.5em;
}
#content-core #searchform .actionMenuContent {
  visibility: hidden;
  position: absolute;
  top: -32600px;
  left: -32600px;
}
#content-core #searchform .actionMenuContent #search-filter {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#content-core #searchform .actionMenuContent #search-filter fieldset {
  width: 48%;
}
#content-core #searchform .actionMenuContent #search-filter fieldset:not(:last-child) {
  margin-right: 1em;
}
#content-core #searchform .actionMenu.activated .actionMenuContent {
  visibility: visible;
  position: initial;
  top: initial;
  left: initial;
}
#content-core #searchform .input-group {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 1em;
}
#content-core #searchform .input-group input[type="text"] {
  float: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  padding: 0.25em 0.5em;
  width: 85%;
  height: 1.5em;
  border-right: 1px solid #ccc;
}
#content-core #searchform .input-group input[type="text"]:focus-visible {
  border-radius: 0;
  outline: 1px solid #003d7f;
}
#content-core #searchform .input-group input[type="submit"] {
  float: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  border-radius: 0;
  width: 15%;
  padding: 0.25em 0.5em;
  color: #3d3d3d;
  background-color: #e6e6e6;
  font-weight: 600;
}
#content-core #searchform .input-group input[type="submit"]:hover {
  color: #000;
  background-color: #ccc;
}
#content-core #searchform #search-results .pagination {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
#content-core #searchform #search-results .pagination ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
  list-style-position: inside;
}
#content-core #searchform #search-results .pagination ul li {
  list-style-type: none;
  list-style-position: inside;
  padding: 0.25em;
}
#content-core #searchform #search-results .pagination ul li a {
  text-decoration: none;
  color: #069;
}
#content-core #searchform #search-results .pagination ul li a .label,
#content-core #searchform #search-results .pagination ul li a .arrow {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: normal;
  color: #069;
}
#content-core #searchform #search-results .pagination ul li a:hover {
  text-decoration: underline;
  color: #003d7f;
}
#content-core #searchform #search-results .pagination ul li a:hover .label:hover,
#content-core #searchform #search-results .pagination ul li a:hover .arrow:hover {
  color: #003d7f;
}
#content-core #searchform #search-results .pagination ul li.previous .label {
  padding-right: 1em;
}
#content-core #searchform #search-results .pagination ul li.previous .arrow::before {
  content: "\00ab";
  margin-right: 0;
}
#content-core #searchform #search-results .pagination ul li.active span {
  font-size: 1.25em;
}
#content-core #searchform #search-results .pagination ul li.next .label {
  padding-left: 1em;
}
#content-core #searchform #search-results .pagination ul li.next .arrow::after {
  content: "\00bb";
  margin-left: 0;
}
.mainnavigation {
  margin-bottom: 2em;
}
.mainnavigation nav ul li,
.mainnavigation nav .ui-accordion li {
  line-height: 1.5em;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #fff;
  width: 100%;
}
.mainnavigation nav ul li .ui-corner-all,
.mainnavigation nav .ui-accordion li .ui-corner-all,
.mainnavigation nav ul li .ui-corner-top,
.mainnavigation nav .ui-accordion li .ui-corner-top,
.mainnavigation nav ul li .ui-corner-bottom,
.mainnavigation nav .ui-accordion li .ui-corner-bottom {
  border-radius: 0;
}
.mainnavigation nav ul a,
.mainnavigation nav .ui-accordion a,
.mainnavigation nav ul a:visited,
.mainnavigation nav .ui-accordion a:visited,
.mainnavigation nav ul a:hover,
.mainnavigation nav .ui-accordion a:hover {
  color: #fff;
  font-weight: normal;
  line-height: 21px;
  text-decoration: none;
  overflow-wrap: break-word;
}
.mainnavigation nav ul a.ui-state-hover,
.mainnavigation nav .ui-accordion a.ui-state-hover,
.mainnavigation nav ul a:visited.ui-state-hover,
.mainnavigation nav .ui-accordion a:visited.ui-state-hover,
.mainnavigation nav ul a:hover.ui-state-hover,
.mainnavigation nav .ui-accordion a:hover.ui-state-hover {
  color: #fff !important;
}
.mainnavigation nav ul > li:first-child > a,
.mainnavigation nav .ui-accordion > li:first-child > a {
  border-top: 0 none;
}
.mainnavigation nav ul > li:last-child > a,
.mainnavigation nav .ui-accordion > li:last-child > a {
  border-bottom: 0 none;
}
.mainnavigation nav ul > li a,
.mainnavigation nav .ui-accordion > li a,
.mainnavigation nav ul > li .ui-accordion-header,
.mainnavigation nav .ui-accordion > li .ui-accordion-header {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background: none;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  background-color: #003d7f;
  padding-left: 1em;
  padding-right: 0.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
.mainnavigation nav ul > li ul,
.mainnavigation nav .ui-accordion > li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
.mainnavigation nav ul > li ul li,
.mainnavigation nav .ui-accordion > li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
.mainnavigation nav ul > li ul li a,
.mainnavigation nav .ui-accordion > li ul li a,
.mainnavigation nav ul > li ul li a:visited,
.mainnavigation nav .ui-accordion > li ul li a:visited,
.mainnavigation nav ul > li ul li a:hover,
.mainnavigation nav .ui-accordion > li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 1em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #003d7f;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
.mainnavigation nav ul > li ul li a.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li a.navTreeCurrentItem,
.mainnavigation nav ul > li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav ul > li ul li a:hover.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li a:hover.navTreeCurrentItem {
  color: #003d7f;
  font-weight: bold;
  background-color: #fff;
}
.mainnavigation nav ul > li ul li ul,
.mainnavigation nav .ui-accordion > li ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
.mainnavigation nav ul > li ul li ul li,
.mainnavigation nav .ui-accordion > li ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
.mainnavigation nav ul > li ul li ul li a,
.mainnavigation nav .ui-accordion > li ul li ul li a,
.mainnavigation nav ul > li ul li ul li a:visited,
.mainnavigation nav .ui-accordion > li ul li ul li a:visited,
.mainnavigation nav ul > li ul li ul li a:hover,
.mainnavigation nav .ui-accordion > li ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 1.75em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #003d7f;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
.mainnavigation nav ul > li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li a:hover.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li a:hover.navTreeCurrentItem {
  color: #003d7f;
  font-weight: bold;
  background-color: #fff;
}
.mainnavigation nav ul > li ul li ul li ul,
.mainnavigation nav .ui-accordion > li ul li ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
.mainnavigation nav ul > li ul li ul li ul li,
.mainnavigation nav .ui-accordion > li ul li ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
.mainnavigation nav ul > li ul li ul li ul li a,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a,
.mainnavigation nav ul > li ul li ul li ul li a:visited,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a:visited,
.mainnavigation nav ul > li ul li ul li ul li a:hover,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 2.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #003d7f;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
.mainnavigation nav ul > li ul li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li ul li a:hover.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li a:hover.navTreeCurrentItem {
  color: #003d7f;
  font-weight: bold;
  background-color: #fff;
}
.mainnavigation nav ul > li ul li ul li ul li ul,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
.mainnavigation nav ul > li ul li ul li ul li ul li,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
.mainnavigation nav ul > li ul li ul li ul li ul li a,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a,
.mainnavigation nav ul > li ul li ul li ul li ul li a:visited,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a:visited,
.mainnavigation nav ul > li ul li ul li ul li ul li a:hover,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 3.25em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #003d7f;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
.mainnavigation nav ul > li ul li ul li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a:visited.navTreeCurrentItem,
.mainnavigation nav ul > li ul li ul li ul li ul li a:hover.navTreeCurrentItem,
.mainnavigation nav .ui-accordion > li ul li ul li ul li ul li a:hover.navTreeCurrentItem {
  color: #003d7f;
  font-weight: bold;
  background-color: #fff;
}
.portlet-static-pulldown {
  border: 1px solid #dcdcdc;
}
@media screen and (min-width: 801px) {
  .portlet-static-pulldown {
    margin-bottom: 0.5em;
  }
}
.portlet-static-pulldown .hubub-no-corners {
  border-radius: 0;
}
.portlet-static-pulldown .portletHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 28px;
  font-weight: bold;
  color: #3d3d3d;
  background: none;
  background-color: #e6e6e6;
}
.portlet-static-pulldown .portletHeader .ui-icon {
  order: 5;
}
.portlet-static-pulldown .portletHeader .tile,
.portlet-static-pulldown .portletHeader .titleText {
  order: 1;
  margin-left: 0.5em;
  flex-grow: 3;
  font-size: 14px;
}
.portlet-static-pulldown .portletHeader.ui-state-hover {
  border: 0 none;
}
.portlet-static-pulldown .portletHeader.ui-state-default .ui-icon {
  background: none !important;
  background-image: none !important;
  text-indent: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: rgba(0, 0, 0, 0.54);
  position: inherit;
  left: unset;
  top: unset;
  width: fit-content;
  height: fit-content;
}
.portlet-static-pulldown .portletContent {
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  padding: 0.5em;
  line-height: 1.4em;
}
@media screen and (max-width: 800px) {
  .portlet-static-pulldown .portletContent {
    background-color: #fff;
  }
}
.portlet-static-pulldown .portletContent.ui-widget-content a {
  color: #069;
}
.portlet-static-pulldown .portletHeader {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.portlet-static-pulldown.reverse {
  border: 1px solid #dcdcdc;
}
@media screen and (min-width: 801px) {
  .portlet-static-pulldown.reverse {
    margin-bottom: 0.5em;
  }
}
.portlet-static-pulldown.reverse .hubub-no-corners {
  border-radius: 0;
}
.portlet-static-pulldown.reverse .portletHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 28px;
  font-weight: bold;
  color: #3d3d3d;
  background: none;
  background-color: #e6e6e6;
}
.portlet-static-pulldown.reverse .portletHeader .ui-icon {
  order: 5;
}
.portlet-static-pulldown.reverse .portletHeader .tile,
.portlet-static-pulldown.reverse .portletHeader .titleText {
  order: 1;
  margin-left: 0.5em;
  flex-grow: 3;
  font-size: 14px;
}
.portlet-static-pulldown.reverse .portletHeader.ui-state-hover {
  border: 0 none;
}
.portlet-static-pulldown.reverse .portletHeader.ui-state-default .ui-icon {
  background: none !important;
  background-image: none !important;
  text-indent: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: rgba(0, 0, 0, 0.54);
  position: inherit;
  left: unset;
  top: unset;
  width: fit-content;
  height: fit-content;
}
.portlet-static-pulldown.reverse .portletContent {
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  padding: 0.5em;
  line-height: 1.4em;
}
@media screen and (max-width: 800px) {
  .portlet-static-pulldown.reverse .portletContent {
    background-color: #fff;
  }
}
.portlet-static-pulldown.reverse .portletContent.ui-widget-content a {
  color: #069;
}
.portlet-static-pulldown.reverse .portletHeader {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.portlet-static-pulldown-image {
  border: 1px solid #dcdcdc;
}
@media screen and (min-width: 801px) {
  .portlet-static-pulldown-image {
    margin-bottom: 0.5em;
  }
}
.portlet-static-pulldown-image .hubub-no-corners {
  border-radius: 0;
}
.portlet-static-pulldown-image .portletHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 28px;
  font-weight: bold;
  color: #3d3d3d;
  background: none;
  background-color: #e6e6e6;
}
.portlet-static-pulldown-image .portletHeader .ui-icon {
  order: 5;
}
.portlet-static-pulldown-image .portletHeader .tile,
.portlet-static-pulldown-image .portletHeader .titleText {
  order: 1;
  margin-left: 0.5em;
  flex-grow: 3;
  font-size: 14px;
}
.portlet-static-pulldown-image .portletHeader.ui-state-hover {
  border: 0 none;
}
.portlet-static-pulldown-image .portletHeader.ui-state-default .ui-icon {
  background: none !important;
  background-image: none !important;
  text-indent: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: rgba(0, 0, 0, 0.54);
  position: inherit;
  left: unset;
  top: unset;
  width: fit-content;
  height: fit-content;
}
.portlet-static-pulldown-image .portletContent {
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  padding: 0.5em;
  line-height: 1.4em;
}
@media screen and (max-width: 800px) {
  .portlet-static-pulldown-image .portletContent {
    background-color: #fff;
  }
}
.portlet-static-pulldown-image .portletContent.ui-widget-content a {
  color: #069;
}
.portlet-static-pulldown-image .portletHeader {
  padding: 0;
}
.portlet-static-pulldown-image .portletHeader .portletHeader {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.portlet-static-pulldown-image.reverse {
  border: 1px solid #dcdcdc;
}
@media screen and (min-width: 801px) {
  .portlet-static-pulldown-image.reverse {
    margin-bottom: 0.5em;
  }
}
.portlet-static-pulldown-image.reverse .hubub-no-corners {
  border-radius: 0;
}
.portlet-static-pulldown-image.reverse .portletHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 28px;
  font-weight: bold;
  color: #3d3d3d;
  background: none;
  background-color: #e6e6e6;
}
.portlet-static-pulldown-image.reverse .portletHeader .ui-icon {
  order: 5;
}
.portlet-static-pulldown-image.reverse .portletHeader .tile,
.portlet-static-pulldown-image.reverse .portletHeader .titleText {
  order: 1;
  margin-left: 0.5em;
  flex-grow: 3;
  font-size: 14px;
}
.portlet-static-pulldown-image.reverse .portletHeader.ui-state-hover {
  border: 0 none;
}
.portlet-static-pulldown-image.reverse .portletHeader.ui-state-default .ui-icon {
  background: none !important;
  background-image: none !important;
  text-indent: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  color: rgba(0, 0, 0, 0.54);
  position: inherit;
  left: unset;
  top: unset;
  width: fit-content;
  height: fit-content;
}
.portlet-static-pulldown-image.reverse .portletContent {
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  padding: 0.5em;
  line-height: 1.4em;
}
@media screen and (max-width: 800px) {
  .portlet-static-pulldown-image.reverse .portletContent {
    background-color: #fff;
  }
}
.portlet-static-pulldown-image.reverse .portletContent.ui-widget-content a {
  color: #069;
}
.portletStaticText {
  border: 1px solid #dcdcdc;
}
@media screen and (min-width: 801px) {
  .portletStaticText {
    margin-bottom: 0.5em;
  }
}
.portletStaticText .hubub-no-corners {
  border-radius: 0;
}
.portletStaticText .portletHeader {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 28px;
  font-weight: bold;
  color: #3d3d3d;
  background: none;
  background-color: #e6e6e6;
}
.portletStaticText .portletHeader .ui-icon {
  order: 5;
}
.portletStaticText .portletHeader .tile,
.portletStaticText .portletHeader .titleText {
  order: 1;
  margin-left: 0.5em;
  flex-grow: 3;
  font-size: 14px;
}
.portletStaticText .portletHeader.ui-state-hover {
  border: 0 none;
}
.portletStaticText .portletContent {
  border-radius: 0;
  border: 0 none;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  padding: 0.5em;
  line-height: 1.4em;
}
@media screen and (max-width: 800px) {
  .portletStaticText .portletContent {
    background-color: #fff;
  }
}
.portletStaticText .portletContent.ui-widget-content a {
  color: #069;
}
@media screen and (max-width: 800px) {
  .portletStaticText {
    background-color: #fff;
  }
}
.portletStaticText > div {
  font-weight: bold;
  color: #666;
}
.portletStaticText > div a {
  text-decoration: none;
  color: #666;
}
.portletStaticText > div a:hover {
  text-decoration: none;
}
.portletStaticText > div a img {
  margin-right: 0.5em;
}
.portletStaticText .portletHeader {
  padding: 0 0 0 0.5em;
}
.portletStaticText .portletContent ul,
.portletStaticText .portletContent li {
  list-style-position: inside;
  list-style-image: none;
  list-style: none inside;
}
#subNavigationPortlet {
  overflow: hidden;
  padding: 0;
  margin-bottom: 2em;
  background-color: unset;
}
@media screen and (max-width: 800px) {
  #subNavigationPortlet {
    background-color: #fff;
    margin-top: 0.5em;
  }
}
#subNavigationPortlet h4.navHeader {
  padding-left: 1em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  color: #8a0f14;
  background-color: unset;
  line-height: 1.5em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-size: 14px;
}
#subNavigationPortlet h4.navHeader a {
  border-bottom: 1px solid #fff;
  color: #8a0f14;
  font-weight: bold;
}
#subNavigationPortlet h4.navHeader a:hover,
#subNavigationPortlet h4.navHeader a:focus {
  text-decoration: none;
}
#subNavigationPortlet ul,
#subNavigationPortlet .ubnav {
  background-color: #e6e6e6;
}
#subNavigationPortlet ul li,
#subNavigationPortlet .ubnav li {
  line-height: 1.5em;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  background-color: #e6e6e6;
  font-weight: normal;
  width: 100%;
}
#subNavigationPortlet ul li a,
#subNavigationPortlet .ubnav li a,
#subNavigationPortlet ul li a:visited,
#subNavigationPortlet .ubnav li a:visited,
#subNavigationPortlet ul li a:hover,
#subNavigationPortlet .ubnav li a:hover {
  padding-left: 1em;
  color: #069;
  display: block;
  text-decoration: none;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}
#subNavigationPortlet ul li a.state-pending,
#subNavigationPortlet .ubnav li a.state-pending,
#subNavigationPortlet ul li a:visited.state-pending,
#subNavigationPortlet .ubnav li a:visited.state-pending,
#subNavigationPortlet ul li a:hover.state-pending,
#subNavigationPortlet .ubnav li a:hover.state-pending {
  background-color: #cde92d !important;
  color: #3d3d3d !important;
}
#subNavigationPortlet ul li a.state-draft,
#subNavigationPortlet .ubnav li a.state-draft,
#subNavigationPortlet ul li a:visited.state-draft,
#subNavigationPortlet .ubnav li a:visited.state-draft,
#subNavigationPortlet ul li a:hover.state-draft,
#subNavigationPortlet .ubnav li a:hover.state-draft {
  background-color: #e39000 !important;
  color: #3d3d3d !important;
}
#subNavigationPortlet ul li a.state-private,
#subNavigationPortlet .ubnav li a.state-private,
#subNavigationPortlet ul li a:visited.state-private,
#subNavigationPortlet .ubnav li a:visited.state-private,
#subNavigationPortlet ul li a:hover.state-private,
#subNavigationPortlet .ubnav li a:hover.state-private {
  background-color: #e53030 !important;
  color: #fff !important;
}
#subNavigationPortlet ul li > a,
#subNavigationPortlet .ubnav li > a {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
#subNavigationPortlet ul a,
#subNavigationPortlet .ubnav a {
  color: #069;
  padding-right: 0.5em;
}
#subNavigationPortlet ul a:visited,
#subNavigationPortlet .ubnav a:visited {
  color: #069;
  padding-right: 0.5em;
}
#subNavigationPortlet ul a:hover,
#subNavigationPortlet .ubnav a:hover {
  text-decoration: none;
}
#subNavigationPortlet ul .navTreeItem.active,
#subNavigationPortlet .ubnav .navTreeItem.active {
  color: #003d7f;
  font-weight: bolder;
  background-color: #fff;
}
#subNavigationPortlet ul ul,
#subNavigationPortlet .ubnav ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #ccc;
}
#subNavigationPortlet ul ul li,
#subNavigationPortlet .ubnav ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #ccc;
  list-style: none outside;
}
#subNavigationPortlet ul ul li a,
#subNavigationPortlet .ubnav ul li a,
#subNavigationPortlet ul ul li a:visited,
#subNavigationPortlet .ubnav ul li a:visited,
#subNavigationPortlet ul ul li a:hover,
#subNavigationPortlet .ubnav ul li a:hover {
  padding-right: 0.5em;
  padding-left: 1.75em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #ccc;
  color: #3d3d3d;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
#subNavigationPortlet ul ul li a.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li a.navTreeCurrentItem,
#subNavigationPortlet ul ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet ul ul li a:hover.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li a:hover.navTreeCurrentItem {
  color: #3d3d3d;
  font-weight: bold;
  background-color: #fff;
}
#subNavigationPortlet ul ul li ul,
#subNavigationPortlet .ubnav ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
#subNavigationPortlet ul ul li ul li,
#subNavigationPortlet .ubnav ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
#subNavigationPortlet ul ul li ul li a,
#subNavigationPortlet .ubnav ul li ul li a,
#subNavigationPortlet ul ul li ul li a:visited,
#subNavigationPortlet .ubnav ul li ul li a:visited,
#subNavigationPortlet ul ul li ul li a:hover,
#subNavigationPortlet .ubnav ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 2.5em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #3d3d3d;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
#subNavigationPortlet ul ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li a:hover.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li a:hover.navTreeCurrentItem {
  color: #3d3d3d;
  font-weight: bold;
  background-color: #fff;
}
#subNavigationPortlet ul ul li ul li ul,
#subNavigationPortlet .ubnav ul li ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
#subNavigationPortlet ul ul li ul li ul li,
#subNavigationPortlet .ubnav ul li ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
#subNavigationPortlet ul ul li ul li ul li a,
#subNavigationPortlet .ubnav ul li ul li ul li a,
#subNavigationPortlet ul ul li ul li ul li a:visited,
#subNavigationPortlet .ubnav ul li ul li ul li a:visited,
#subNavigationPortlet ul ul li ul li ul li a:hover,
#subNavigationPortlet .ubnav ul li ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 3.25em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #3d3d3d;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
#subNavigationPortlet ul ul li ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li ul li a:hover.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li a:hover.navTreeCurrentItem {
  color: #3d3d3d;
  font-weight: bold;
  background-color: #fff;
}
#subNavigationPortlet ul ul li ul li ul li ul,
#subNavigationPortlet .ubnav ul li ul li ul li ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0 none;
  background-color: #e6e6e6;
}
#subNavigationPortlet ul ul li ul li ul li ul li,
#subNavigationPortlet .ubnav ul li ul li ul li ul li {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #e6e6e6;
  list-style: none outside;
}
#subNavigationPortlet ul ul li ul li ul li ul li a,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a,
#subNavigationPortlet ul ul li ul li ul li ul li a:visited,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a:visited,
#subNavigationPortlet ul ul li ul li ul li ul li a:hover,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a:hover {
  padding-right: 0.5em;
  padding-left: 4em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  background-color: #e6e6e6;
  color: #3d3d3d;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
}
#subNavigationPortlet ul ul li ul li ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a:visited.navTreeCurrentItem,
#subNavigationPortlet ul ul li ul li ul li ul li a:hover.navTreeCurrentItem,
#subNavigationPortlet .ubnav ul li ul li ul li ul li a:hover.navTreeCurrentItem {
  color: #3d3d3d;
  font-weight: bold;
  background-color: #fff;
}
.portletwrapper-mainnavigation {
  order: 1 !important;
}
.portletwrapper-locations-de,
.portletwrapper-locations-en,
.portletwrapper-locations {
  order: 2 !important;
}
.portletwrapper-subjects-de,
.portletwrapper-subjects-en,
.portletwrapper-subjects {
  order: 2 !important;
}
.portletwrapper-histcollections-de,
.portletwrapper-histcollections-en,
.portletwrapper-histcollections {
  order: 2 !important;
}
.portletwrapper-fami-de,
.portletwrapper-fami-en,
.portletwrapper-fami {
  order: 2 !important;
}
.portletwrapper-subnavigation {
  order: 2 !important;
}
div[class^="portletwrapper"] {
  order: 50;
}
@media screen and (min-width: 801px) {
  #primo-login-wrapper {
    position: absolute;
    height: 111px;
    width: 200px;
    background-color: #003d7f;
    color: #fff;
  }
}
@media screen and (max-width: 800px) {
  #primo-login-wrapper {
    display: none;
  }
}
@media screen and (min-width: 801px) {
  #primo-login-wrapper #primo-login {
    border: 0 none;
    margin: 0;
    margin: 0.5em 0.5em 0.5em 1em;
  }
  #primo-login-wrapper #primo-login .viewlet-head {
    font-weight: bolder;
    margin-bottom: 0.5em;
  }
  #primo-login-wrapper #primo-login .viewlet-link {
    float: right;
    margin-top: 0.5em;
    font-weight: bolder;
  }
  #primo-login-wrapper #primo-login .viewlet-link a {
    color: #fff;
    font-size: 0.8571em;
    text-decoration: none;
    font-style: italic;
  }
}
.template-sendto_form #col3_content form.rowlike {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.template-sendto_form #col3_content form.rowlike .field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field:hover {
  background-color: #e6e6e6;
}
.template-sendto_form #col3_content form.rowlike .field .fieldErrorBox {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-indent: 1em;
  background-color: #f55;
  color: #fff;
  font-weight: bold;
}
.template-sendto_form #col3_content form.rowlike .field .option {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
.template-sendto_form #col3_content form.rowlike .field .option .bool-field,
.template-sendto_form #col3_content form.rowlike .field .option .tuple-field,
.template-sendto_form #col3_content form.rowlike .field .option .list-field,
.template-sendto_form #col3_content form.rowlike .field .option .checkbox-widget {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1em;
  order: 1;
  display: inline-block;
}
.template-sendto_form #col3_content form.rowlike .field .option > input[type="checkbox"] {
  order: 1;
  margin-right: 0;
}
@media screen and (min-width: 801px) {
  .template-sendto_form #col3_content form.rowlike .field .option > input[type="checkbox"] {
    width: 5%;
  }
}
@media screen and (max-width: 800px) {
  .template-sendto_form #col3_content form.rowlike .field .option > input[type="checkbox"] {
    width: 2.5em;
  }
}
.template-sendto_form #col3_content form.rowlike .field .option > label.horizontal {
  order: 2;
}
@media screen and (min-width: 801px) {
  .template-sendto_form #col3_content form.rowlike .field .option > label.horizontal {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .template-sendto_form #col3_content form.rowlike .field .option > label.horizontal {
    width: 80%;
  }
}
.template-sendto_form #col3_content form.rowlike .field .option > label.horizontal > span {
  order: 2;
}
.template-sendto_form #col3_content form.rowlike .field .option > label.horizontal .formHelp {
  margin: 0.25em 0;
}
.template-sendto_form #col3_content form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
  order: 2;
}
@media screen and (min-width: 801px) {
  .template-sendto_form #col3_content form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  .template-sendto_form #col3_content form.rowlike .field .option > input.single-checkbox-bool-widget.bool-field ~ label {
    width: 80%;
  }
}
.template-sendto_form #col3_content form.rowlike .field .option > div {
  order: 3;
}
@media screen and (min-width: 801px) {
  .template-sendto_form #col3_content form.rowlike .field .option > div {
    margin-left: 5%;
  }
}
@media screen and (max-width: 800px) {
  .template-sendto_form #col3_content form.rowlike .field .option > div {
    margin-left: 2.5em;
  }
}
.template-sendto_form #col3_content form.rowlike .field .option label {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  order: 2;
  display: inline-block;
}
.template-sendto_form #col3_content form.rowlike .field .option label .label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .option label input.radio-widget.required::after {
  display: none;
}
.template-sendto_form #col3_content form.rowlike .field .textline-field,
.template-sendto_form #col3_content form.rowlike .field .asciiline-field,
.template-sendto_form #col3_content form.rowlike .field .int-field,
.template-sendto_form #col3_content form.rowlike .field .email-field,
.template-sendto_form #col3_content form.rowlike .field .uri-field {
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-sendto_form #col3_content form.rowlike .field .textline-field:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .asciiline-field:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .int-field:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .email-field:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .uri-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-sendto_form #col3_content form.rowlike .field .text-field,
.template-sendto_form #col3_content form.rowlike .field .textarea-widget,
.template-sendto_form #col3_content form.rowlike .field .json-widget {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-sendto_form #col3_content form.rowlike .field .text-field:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .textarea-widget:focus-visible,
.template-sendto_form #col3_content form.rowlike .field .json-widget:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-sendto_form #col3_content form.rowlike .field .choice-field {
  padding: 0.25em;
}
.template-sendto_form #col3_content form.rowlike .field .tuple-field {
  padding: 0.25em;
  height: 7.5em;
  border: 1px solid #ccc;
  border-radius: 0;
}
.template-sendto_form #col3_content form.rowlike .field .tuple-field:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-sendto_form #col3_content form.rowlike .field .tuple-field.checkbox-widget {
  height: auto;
}
.template-sendto_form #col3_content form.rowlike .field .tuple-field.select2-orderable {
  height: auto;
}
.template-sendto_form #col3_content form.rowlike .field .tuple-field.select2-orderable .select2-choices {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: transparent;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls.pull-right {
  float: none !important;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.pull-right {
  float: none !important;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown.open .dropdown-menu {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: flex-end;
  align-content: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  min-width: fit-content;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  list-style-type: none;
  list-style-position: inside;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li {
  list-style-type: none;
  list-style-position: inside;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-relateditems-container .toolbar .controls .dropdown .dropdown-menu li a {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.2em;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper > *:not(:first-child) {
  margin-left: 1em !important;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 40%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 40%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap {
  padding: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: unset;
  position: unset;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--year {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 1;
  height: 2em;
  width: 20%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__select--month {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  order: 2;
  height: 2em;
  width: 35%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--prev {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: -1;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__header .picker__nav--next {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 0.75em 0.5em;
  order: 100;
  left: unset;
  right: unset;
  top: unset;
  position: unset;
  box-sizing: unset;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__day {
  padding: 0.25em;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table .picker__weekday {
  padding: 0.25em;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__table td {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 20%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
  line-height: 1.5em;
  font-size: 14px;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  min-width: unset;
  max-width: unset;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  width: 20%;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #3d3d3d;
  padding: 0.5em 0.75em;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item:hover {
  background-color: #e6e6e6;
  border-color: #ccc;
  color: #3d3d3d !important;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker .picker__holder .picker__frame .picker__wrap .picker__box .picker__list-item--selected {
  color: #fff;
  background-color: #069;
  font-weight: 600;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-now {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .pattern-pickadate-wrapper .pattern-pickadate-clear {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field {
  margin-bottom: 0 !important;
}
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field .list-field,
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field button[name="from2toButton"],
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field button[name="to2fromButton"],
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field button[name="upButton"],
.template-sendto_form #col3_content form.rowlike .field .ordered-selection-field button[name="downButton"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal {
  background-color: #fff;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType {
  padding: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.external input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0.25em;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  line-height: 1.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkType.email input[type="text"]:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .linkLabel {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bolder;
  display: block;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.remoteUrl .linkModal .linkTypes .form-group label {
  color: #707070;
  font-weight: normal;
}
.template-sendto_form #col3_content form.rowlike .field .multi-widget-field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="riedit"],
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rimain button[name="ridelete"] {
  line-height: 1.5em;
  padding: 0.25em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences {
  margin-top: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence {
  border: 0 none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .occurrence:hover {
  border: 0 none;
}
.template-sendto_form #col3_content form.rowlike .field.kssattr-fieldname-form\.widgets\.IEventRecurrence\.recurrence .ridisplay .rioccurrences .batching {
  font-size: 1em;
}
.template-sendto_form #col3_content form.rowlike .field label {
  font-weight: 600;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.template-sendto_form #col3_content form.rowlike .field label,
.template-sendto_form #col3_content form.rowlike .field label .label {
  color: #3d3d3d;
  line-height: 1.125em;
  font-size: 14px;
  white-space: inherit;
}
.template-sendto_form #col3_content form.rowlike .field label .required::after {
  color: #8a0f14;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 20px;
  width: 20px;
  content: 'info' !important;
  vertical-align: text-bottom;
}
.template-sendto_form #col3_content form.rowlike .field label .formHelp {
  font-weight: 400;
  font-style: italic;
  display: block;
  color: #707070;
}
.template-sendto_form #col3_content form.rowlike .formControls {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .formControls .button-field,
.template-sendto_form #col3_content form.rowlike .formControls button {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.template-sendto_form #col3_content form.rowlike .formControls .button-field:not(:last-of-type),
.template-sendto_form #col3_content form.rowlike .formControls button:not(:last-of-type) {
  margin-right: 0.5em;
}
@media all {
  .sprite_arr_north {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: -75px 0px;
  }
  .sprite_arr_east {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: -23px 2px;
  }
  .sprite_arr_south {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: -48px 2px;
  }
  .sprite_arr_west {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: 0px 0px;
  }
  .sprite_lens {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: -56px -25px;
  }
  .sprite_on_off {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    background-position: -25px -25px;
  }
  .ui-icon {
    background-image: url('++theme++hub-ub-theme/hubub2/deploy/img/sprites.png');
    /* for production|plone use url('../img/sprites.png'); */
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
  }
}
@media all {
  #stage {
    margin-top: 2.0714em;
    /* 29px depending on font-size */
    margin-bottom: -179px;
    /* depending on font-size */
    position: relative;
    height: 150px;
  }
}
@media screen and (max-width: 800px) {
  #stage {
    display: none;
  }
}
#col3_content table,
#content table,
#tinymce table {
  border-collapse: collapse;
  margin-bottom: 2em;
  width: auto;
}
#col3_content table .odd,
#content table .odd,
#tinymce table .odd,
#col3_content table .even,
#content table .even,
#tinymce table .even {
  background-color: unset;
}
#col3_content table thead th,
#content table thead th,
#tinymce table thead th {
  background: none;
  border: 0 none;
  color: #3d3d3d;
}
#col3_content table tbody th,
#content table tbody th,
#tinymce table tbody th {
  background: none;
  border: 0 none;
}
#col3_content table tbody tr,
#content table tbody tr,
#tinymce table tbody tr {
  border: 0 none;
}
#col3_content table tbody td,
#content table tbody td,
#tinymce table tbody td {
  border: 0 none;
}
#col3_content table td,
#content table td,
#tinymce table td {
  padding: 0.5em;
}
#col3_content table th,
#content table th,
#tinymce table th {
  padding: 0.5em;
}
#col3_content table caption,
#content table caption,
#tinymce table caption {
  font-variant: small-caps;
}
#col3_content table.invisible tbody tr,
#content table.invisible tbody tr,
#tinymce table.invisible tbody tr,
#col3_content table.invisible tbody th,
#content table.invisible tbody th,
#tinymce table.invisible tbody th,
#col3_content table.invisible tbody td,
#content table.invisible tbody td,
#tinymce table.invisible tbody td {
  border: 0 none;
  background: none;
}
#col3_content table.listing tbody,
#content table.listing tbody,
#tinymce table.listing tbody {
  border-bottom: 1px solid #ccc;
}
#col3_content table.listing th,
#content table.listing th,
#tinymce table.listing th {
  background: none repeat scroll 0 0 #e6e6e6;
}
#col3_content table.listing tr,
#content table.listing tr,
#tinymce table.listing tr {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
#col3_content table.listing tr th,
#content table.listing tr th,
#tinymce table.listing tr th {
  border: 1px solid #ccc;
}
#col3_content table.listing tr.odd:first-child,
#content table.listing tr.odd:first-child,
#tinymce table.listing tr.odd:first-child {
  border-top: 1px solid #ccc;
}
#col3_content table.plain th,
#content table.plain th,
#tinymce table.plain th,
#col3_content table.plain tr,
#content table.plain tr,
#tinymce table.plain tr,
#col3_content table.plain td,
#content table.plain td,
#tinymce table.plain td {
  border: 1px solid #ccc;
}
#col3_content table.grid td,
#content table.grid td,
#tinymce table.grid td {
  border: 1px solid #ccc;
}
#col3_content table.grid tr.odd,
#content table.grid tr.odd,
#tinymce table.grid tr.odd {
  background: none repeat scroll 0 0 #e6e6e6;
}
#col3_content table.grid thead,
#content table.grid thead,
#tinymce table.grid thead {
  background-color: #e6e6e6;
}
#col3_content table.grid thead tr,
#content table.grid thead tr,
#tinymce table.grid thead tr,
#col3_content table.grid thead td,
#content table.grid thead td,
#tinymce table.grid thead td {
  border: 1px solid #ccc;
}
#col3_content .location-info-container {
  width: 100%;
}
@media screen and (min-width: 801px) {
  #col3_content .location-info-container {
    column-count: 2;
    column-width: 49.5%;
    column-gap: 1%;
  }
}
@media screen and (max-width: 800px) {
  #col3_content .location-info-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
#col3_content .location-info-item {
  break-inside: avoid;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  background-color: #e6e6e6;
  margin-bottom: 0.5em;
  width: 100%;
}
#col3_content .location-img {
  width: 75px;
  min-width: 75px;
}
#col3_content .location-data {
  padding-left: 0.5em;
  padding-right: 0.25em;
  width: max-content;
}
#col3_content .location-data h3 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  font-size: 12px;
  line-height: 1em;
}
#col3_content .location-data ul {
  list-style-type: none;
  list-style-position: inside;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-size: 12px;
  margin-bottom: 0.25em;
}
#col3_content .location-data ul .more_link::after {
  content: "\00bb";
}
#col3_content .location-data ul li {
  line-height: 18px;
  color: #3d3d3d;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content .tile-title {
  font-size: 1em;
  font-weight: bolder;
  color: #003d7f !important;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=topList] {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=topList] ul.topList {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] {
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] ul.ui-tabs-nav {
  border: 0 none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  margin-left: 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] ul.ui-tabs-nav .ui-tabs-tab a {
  text-decoration: none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] .ui-tabs-panel {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] .ui-tabs-panel ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] .ui-tabs-panel ul li a {
  color: #069;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content div[id^=dbList] div[id^=dbisDbList] .ui-tabs-panel ul li a:hover {
  color: #003d7f;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.dbis-tile .mosaic-tile-content .dbis-link {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.journals_listing-tile .mosaic-tile-content .tile-title {
  font-size: 1em;
  font-weight: bolder;
  color: #003d7f !important;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.journals_listing-tile .mosaic-tile-content div[id^=topList] {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.journals_listing-tile .mosaic-tile-content div[id^=topList] ul.topList {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.journals_listing-tile .mosaic-tile-content .ezb-link {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content .tile-title {
  font-size: 1em;
  font-weight: bolder;
  color: #003d7f !important;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] {
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] ul.ui-tabs-nav {
  border: 0 none;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  margin-left: 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] ul.ui-tabs-nav .ui-tabs-tab a {
  text-decoration: none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] .ui-tabs-panel {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] .ui-tabs-panel ul {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] .ui-tabs-panel ul li a {
  color: #069;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content div[id^=jourList] div[id^=ezbJourList] .ui-tabs-panel ul li a:hover {
  color: #003d7f;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.subject_journals_listing-tile .mosaic-tile-content .ezb-link {
  margin-bottom: 1em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile {
  width: 100%;
  margin-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile .tile-headline2 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-description p {
  margin-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-container {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
  background-color: #003d7f;
  margin-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-container.last-item {
  margin-bottom: 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article {
  height: 75px;
  overflow: hidden;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article header {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-image {
  width: 75px;
  height: 75px;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  width: calc(100% - 75px);
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head {
  margin: 0.8em 0.25em 0.5em 0.5em;
}
@media screen and (min-width: 801px) {
  #main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head {
    width: 80%;
  }
}
@media screen and (max-width: 800px) {
  #main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head {
    width: 100%;
  }
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head a {
  text-decoration: none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head a h3 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  color: #fff !important;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head a h3:hover {
  text-decoration: underline;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-head-description {
  color: #fff;
}
@media screen and (min-width: 801px) {
  #main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-readmore {
    margin: 0.8em 0.3em 0.5em 0.25em;
    width: 20%;
    color: #fff;
    text-decoration: none;
    text-align: right;
  }
}
@media screen and (max-width: 800px) {
  #main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-readmore {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-readmore::after {
  content: "\00bb";
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-topnewsList .topnews-article-teaser-readmore:hover {
  text-decoration: underline;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  list-style-type: none;
  list-style-position: inside;
  border: 1px solid #ccc;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container {
  padding: 0.5em 0.3em 0 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.item-1 {
  border-bottom: 1px solid #fff;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.item-1,
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.item-2 {
  background-color: #e6e6e6;
  padding: 0.5em 0.3em 0.5em 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.item-1 .news-article,
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.item-2 .news-article {
  border: 0 none;
  padding-bottom: 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-container.last-item .news-article {
  border: 0 none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 0.4em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-date {
  max-width: 50%;
  margin-right: 0.25em;
  color: #3d3d3d;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-readmore {
  max-width: 50%;
  margin-left: 0.25em;
}
@media screen and (max-width: 800px) {
  #main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-readmore {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-readmore a {
  text-decoration: none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-readmore a:hover {
  text-decoration: underline;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-byline-readmore a::after {
  content: "\00bb";
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-teaser-head a {
  text-decoration: none;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-teaser-head a:hover {
  text-decoration: underline;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-teaser-head a h3 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  color: #3d3d3d !important;
  line-height: 1.3em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-newsList .news-article-teaser-head-description {
  color: #3d3d3d;
  margin-top: 0.25em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.plone\.tiles\.newsListing-tile .newslisting-tile-footnote {
  margin: 0.5em 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .collage-row-header .tile-headline2 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .listing.line-by-line .feed-entry {
  margin-top: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .listing.line-by-line .feed-entry-header-title {
  margin-top: 0.5em;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .listing.line-by-line .feed-entry-content-description * {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .listing.line-by-line .feed-entry-content-byline-link {
  text-align: right;
}
#main #col3 #col3_content .mosaic-grid-cell .mosaic-hubub\.feedaggregator\.rss-tile .hubub-feedaggregator .read-more {
  margin-top: 1em;
  text-align: right;
}
#content .bg_blue,
#col3_content .bg_blue,
#tinymce .bg_blue,
.hububTmpl .bg_blue {
  margin-top: 0.5em;
  padding: 0.75em;
  width: 98%;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  background-color: #b8cfe5;
}
@media screen and (max-width: 800px) {
  #content .bg_blue .col_w20l,
  #col3_content .bg_blue .col_w20l,
  #tinymce .bg_blue .col_w20l,
  .hububTmpl .bg_blue .col_w20l,
  #content .bg_blue .col_w80l,
  #col3_content .bg_blue .col_w80l,
  #tinymce .bg_blue .col_w80l,
  .hububTmpl .bg_blue .col_w80l {
    float: none;
  }
  #content .bg_blue .col_w20r,
  #col3_content .bg_blue .col_w20r,
  #tinymce .bg_blue .col_w20r,
  .hububTmpl .bg_blue .col_w20r,
  #content .bg_blue .col_w80r,
  #col3_content .bg_blue .col_w80r,
  #tinymce .bg_blue .col_w80r,
  .hububTmpl .bg_blue .col_w80r {
    float: none;
  }
}
@media screen and (max-width: 480px) {
  #content .bg_blue .col_w10l,
  #col3_content .bg_blue .col_w10l,
  #tinymce .bg_blue .col_w10l,
  .hububTmpl .bg_blue .col_w10l {
    float: none;
    display: inline;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_blue .subcr,
  #col3_content .bg_blue .subcr,
  #tinymce .bg_blue .subcr,
  .hububTmpl .bg_blue .subcr {
    padding-left: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_blue .subcr,
  #col3_content .bg_blue .subcr,
  #tinymce .bg_blue .subcr,
  .hububTmpl .bg_blue .subcr {
    padding-left: 0;
    margin-top: 0.75em;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_blue .subcl,
  #col3_content .bg_blue .subcl,
  #tinymce .bg_blue .subcl,
  .hububTmpl .bg_blue .subcl {
    padding-right: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_blue .subcl,
  #col3_content .bg_blue .subcl,
  #tinymce .bg_blue .subcl,
  .hububTmpl .bg_blue .subcl {
    padding-right: 0;
    text-align: center;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_blue img,
  #col3_content .bg_blue img,
  #tinymce .bg_blue img,
  .hububTmpl .bg_blue img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_blue img,
  #col3_content .bg_blue img,
  #tinymce .bg_blue img,
  .hububTmpl .bg_blue img {
    width: 33%;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  #content .bg_blue img,
  #col3_content .bg_blue img,
  #tinymce .bg_blue img,
  .hububTmpl .bg_blue img {
    width: 50%;
  }
}
#content .bg_blue p:last-child,
#col3_content .bg_blue p:last-child,
#tinymce .bg_blue p:last-child,
.hububTmpl .bg_blue p:last-child {
  margin-bottom: 0;
}
#content .bg_grey,
#col3_content .bg_grey,
#tinymce .bg_grey,
.hububTmpl .bg_grey {
  margin-top: 0.5em;
  padding: 0.75em;
  width: 98%;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  background-color: #e6e6e6;
}
@media screen and (max-width: 800px) {
  #content .bg_grey .col_w20l,
  #col3_content .bg_grey .col_w20l,
  #tinymce .bg_grey .col_w20l,
  .hububTmpl .bg_grey .col_w20l,
  #content .bg_grey .col_w80l,
  #col3_content .bg_grey .col_w80l,
  #tinymce .bg_grey .col_w80l,
  .hububTmpl .bg_grey .col_w80l {
    float: none;
  }
  #content .bg_grey .col_w20r,
  #col3_content .bg_grey .col_w20r,
  #tinymce .bg_grey .col_w20r,
  .hububTmpl .bg_grey .col_w20r,
  #content .bg_grey .col_w80r,
  #col3_content .bg_grey .col_w80r,
  #tinymce .bg_grey .col_w80r,
  .hububTmpl .bg_grey .col_w80r {
    float: none;
  }
}
@media screen and (max-width: 480px) {
  #content .bg_grey .col_w10l,
  #col3_content .bg_grey .col_w10l,
  #tinymce .bg_grey .col_w10l,
  .hububTmpl .bg_grey .col_w10l {
    float: none;
    display: inline;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_grey .subcr,
  #col3_content .bg_grey .subcr,
  #tinymce .bg_grey .subcr,
  .hububTmpl .bg_grey .subcr {
    padding-left: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_grey .subcr,
  #col3_content .bg_grey .subcr,
  #tinymce .bg_grey .subcr,
  .hububTmpl .bg_grey .subcr {
    padding-left: 0;
    margin-top: 0.75em;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_grey .subcl,
  #col3_content .bg_grey .subcl,
  #tinymce .bg_grey .subcl,
  .hububTmpl .bg_grey .subcl {
    padding-right: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_grey .subcl,
  #col3_content .bg_grey .subcl,
  #tinymce .bg_grey .subcl,
  .hububTmpl .bg_grey .subcl {
    padding-right: 0;
    text-align: center;
  }
}
@media screen and (min-width: 801px) {
  #content .bg_grey img,
  #col3_content .bg_grey img,
  #tinymce .bg_grey img,
  .hububTmpl .bg_grey img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #content .bg_grey img,
  #col3_content .bg_grey img,
  #tinymce .bg_grey img,
  .hububTmpl .bg_grey img {
    width: 33%;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  #content .bg_grey img,
  #col3_content .bg_grey img,
  #tinymce .bg_grey img,
  .hububTmpl .bg_grey img {
    width: 50%;
  }
}
#content .bg_grey p:last-child,
#col3_content .bg_grey p:last-child,
#tinymce .bg_grey p:last-child,
.hububTmpl .bg_grey p:last-child {
  margin-bottom: 0;
}
#content .neutral,
#col3_content .neutral,
#tinymce .neutral,
.hububTmpl .neutral {
  margin-top: 0.5em;
  padding: 0.75em;
  width: 98%;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media screen and (max-width: 800px) {
  #content .neutral .col_w20l,
  #col3_content .neutral .col_w20l,
  #tinymce .neutral .col_w20l,
  .hububTmpl .neutral .col_w20l,
  #content .neutral .col_w80l,
  #col3_content .neutral .col_w80l,
  #tinymce .neutral .col_w80l,
  .hububTmpl .neutral .col_w80l {
    float: none;
  }
  #content .neutral .col_w20r,
  #col3_content .neutral .col_w20r,
  #tinymce .neutral .col_w20r,
  .hububTmpl .neutral .col_w20r,
  #content .neutral .col_w80r,
  #col3_content .neutral .col_w80r,
  #tinymce .neutral .col_w80r,
  .hububTmpl .neutral .col_w80r {
    float: none;
  }
}
@media screen and (max-width: 480px) {
  #content .neutral .col_w10l,
  #col3_content .neutral .col_w10l,
  #tinymce .neutral .col_w10l,
  .hububTmpl .neutral .col_w10l {
    float: none;
    display: inline;
  }
}
@media screen and (min-width: 801px) {
  #content .neutral .subcr,
  #col3_content .neutral .subcr,
  #tinymce .neutral .subcr,
  .hububTmpl .neutral .subcr {
    padding-left: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .neutral .subcr,
  #col3_content .neutral .subcr,
  #tinymce .neutral .subcr,
  .hububTmpl .neutral .subcr {
    padding-left: 0;
    margin-top: 0.75em;
  }
}
@media screen and (min-width: 801px) {
  #content .neutral .subcl,
  #col3_content .neutral .subcl,
  #tinymce .neutral .subcl,
  .hububTmpl .neutral .subcl {
    padding-right: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #content .neutral .subcl,
  #col3_content .neutral .subcl,
  #tinymce .neutral .subcl,
  .hububTmpl .neutral .subcl {
    padding-right: 0;
    text-align: center;
  }
}
@media screen and (min-width: 801px) {
  #content .neutral img,
  #col3_content .neutral img,
  #tinymce .neutral img,
  .hububTmpl .neutral img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #content .neutral img,
  #col3_content .neutral img,
  #tinymce .neutral img,
  .hububTmpl .neutral img {
    width: 33%;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  #content .neutral img,
  #col3_content .neutral img,
  #tinymce .neutral img,
  .hububTmpl .neutral img {
    width: 50%;
  }
}
#content .neutral p:last-child,
#col3_content .neutral p:last-child,
#tinymce .neutral p:last-child,
.hububTmpl .neutral p:last-child {
  margin-bottom: 0;
}
#content .greyborder,
#col3_content .greyborder,
#tinymce .greyborder,
.hububTmpl .greyborder {
  border: 2px solid #ccc;
}
@media screen and (max-width: 800px) {
  #content .floating .col_w10l,
  #col3_content .floating .col_w10l,
  #tinymce .floating .col_w10l,
  .hububTmpl .floating .col_w10l {
    width: 10%;
    display: table;
  }
}
@media screen and (min-width: 801px) {
  #content .floating .col_w90r,
  #col3_content .floating .col_w90r,
  #tinymce .floating .col_w90r,
  .hububTmpl .floating .col_w90r {
    float: none;
  }
}
@media screen and (max-width: 800px) {
  #content .floating .col_w90r,
  #col3_content .floating .col_w90r,
  #tinymce .floating .col_w90r,
  .hububTmpl .floating .col_w90r {
    width: 90%;
    float: none;
  }
}
@media screen and (max-width: 800px) {
  #content .floating .subcl,
  #col3_content .floating .subcl,
  #tinymce .floating .subcl,
  .hububTmpl .floating .subcl {
    text-align: left;
    margin-right: 0.75em;
  }
}
@media screen and (max-width: 800px) {
  #content .floating .subcr,
  #col3_content .floating .subcr,
  #tinymce .floating .subcr,
  .hububTmpl .floating .subcr {
    margin-top: 0;
  }
}
@media screen and (max-width: 800px) {
  #content .floating img,
  #col3_content .floating img,
  #tinymce .floating img,
  .hububTmpl .floating img {
    width: auto;
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  #content .floating img,
  #col3_content .floating img,
  #tinymce .floating img,
  .hububTmpl .floating img {
    width: auto;
  }
}
#content .img-fl-left,
#col3_content .img-fl-left,
#tinymce .img-fl-left,
.hububTmpl .img-fl-left {
  margin-bottom: 0.25em;
  width: 100%;
}
#content .img-fl-left img,
#col3_content .img-fl-left img,
#tinymce .img-fl-left img,
.hububTmpl .img-fl-left img {
  width: 100%;
}
#content .img-fl-left-caption,
#col3_content .img-fl-left-caption,
#tinymce .img-fl-left-caption,
.hububTmpl .img-fl-left-caption {
  float: left;
  margin: 0 0.5em 0.5em 0;
  min-width: 150px;
  clear: left;
}
#content .img-fl-right,
#col3_content .img-fl-right,
#tinymce .img-fl-right,
.hububTmpl .img-fl-right {
  margin-bottom: 0.25em;
  width: 100%;
}
#content .img-fl-right img,
#col3_content .img-fl-right img,
#tinymce .img-fl-right img,
.hububTmpl .img-fl-right img {
  width: 100%;
}
#content .img-fl-right-caption,
#col3_content .img-fl-right-caption,
#tinymce .img-fl-right-caption,
.hububTmpl .img-fl-right-caption {
  float: right;
  margin: 0 0 0.5em 0.5em;
  min-width: 150px;
  clear: right;
}
#content .cap-w20,
#col3_content .cap-w20,
#tinymce .cap-w20,
.hububTmpl .cap-w20 {
  width: 20%;
}
#content .cap-w25,
#col3_content .cap-w25,
#tinymce .cap-w25,
.hububTmpl .cap-w25 {
  width: 25%;
}
#content .cap-w33,
#col3_content .cap-w33,
#tinymce .cap-w33,
.hububTmpl .cap-w33 {
  width: 33%;
}
#content .cap-w50,
#col3_content .cap-w50,
#tinymce .cap-w50,
.hububTmpl .cap-w50 {
  width: 50%;
}
#content .paper-cols,
#col3_content .paper-cols,
#tinymce .paper-cols,
.hububTmpl .paper-cols {
  column-gap: 2em;
  -moz-column-gap: 2em;
  -webkit-column-gap: 2em;
  margin-bottom: 1em;
  text-align: justify;
  column-fill: balance;
  -moz-column-fill: balance;
}
#content .paper-cols.col-count2,
#col3_content .paper-cols.col-count2,
#tinymce .paper-cols.col-count2,
.hububTmpl .paper-cols.col-count2 {
  column-count: 2;
  -moz-column-count: 2;
  -webkit-column-count: 2;
}
#content .paper-cols.col-count3,
#col3_content .paper-cols.col-count3,
#tinymce .paper-cols.col-count3,
.hububTmpl .paper-cols.col-count3 {
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;
}
#content .paper-cols p > img,
#col3_content .paper-cols p > img,
#tinymce .paper-cols p > img,
.hububTmpl .paper-cols p > img {
  width: 100%;
}
.site-de #content .img-caption p,
.site-de #col3_content .img-caption p,
.site-de .hububTmpl .img-caption p {
  font-size: 85%;
  font-weight: normal;
  margin: 0 !important;
  padding: 0;
}
.site-de #content .img-caption p strong,
.site-de #col3_content .img-caption p strong,
.site-de .hububTmpl .img-caption p strong {
  font-weight: normal !important;
}
.site-de #content .img-caption p strong.caption-prefix::before,
.site-de #col3_content .img-caption p strong.caption-prefix::before,
.site-de .hububTmpl .img-caption p strong.caption-prefix::before {
  content: "Abb.:";
}
.site-en #content .img-caption p,
.site-en #col3_content .img-caption p,
.site-en .hububTmpl .img-caption p {
  font-size: 85%;
  font-weight: normal;
  margin: 0 !important;
  padding: 0;
}
.site-en #content .img-caption p strong,
.site-en #col3_content .img-caption p strong,
.site-en .hububTmpl .img-caption p strong {
  font-weight: normal !important;
}
.site-en #content .img-caption p strong.caption-prefix::before,
.site-en #col3_content .img-caption p strong.caption-prefix::before,
.site-en .hububTmpl .img-caption p strong.caption-prefix::before {
  content: "Fig.:";
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-nav {
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  counter-reset: head-1;
}
@media screen and (min-width: 801px) {
  .frontend #col3_content #content-core .pat-autotoc .autotoc-nav {
    font-size: 12px;
    max-width: 33.333%;
  }
}
@media screen and (max-width: 800px) {
  .frontend #col3_content #content-core .pat-autotoc .autotoc-nav {
    float: none;
    width: 100%;
    margin: 0.75em 0;
    font-size: 12px;
  }
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-nav a {
  text-decoration: none;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-nav::before {
  display: block;
  background-color: #ccc;
  padding: 0.25em 0.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.site-de.frontend #col3_content #content-core .pat-autotoc .autotoc-nav::before {
  content: 'Inhaltsverzeichnis';
}
.site-en.frontend #col3_content #content-core .pat-autotoc .autotoc-nav::before {
  content: 'Contents';
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-1 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 0.25em 0 0.25em;
  font-size: 12px;
  line-height: 1.5em;
  counter-reset: head-2 head-3 head-4;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-1:hover {
  background-color: #dcdcdc;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-1::before {
  padding-right: 0.5em;
  counter-increment: head-1;
  content: counter(head-1) ".";
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-2 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 0.25em 0 0.75em;
  font-size: 12px;
  line-height: 1.5em;
  counter-reset: head-3 head-4;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-2:hover {
  background-color: #dcdcdc;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-2::before {
  padding-right: 0.5em;
  counter-increment: head-2;
  content: counter(head-1) "." counter(head-2);
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-3 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 0.25em 0 1.25em;
  font-size: 12px;
  line-height: 1.5em;
  counter-reset: head-4;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-3:hover {
  background-color: #dcdcdc;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-3::before {
  padding-right: 0.5em;
  counter-increment: head-3;
  content: counter(head-1) "." counter(head-2) "." counter(head-3);
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-4 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0 0.25em 0 1.75em;
  font-size: 12px;
  line-height: 1.5em;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-4:hover {
  background-color: #dcdcdc;
}
.frontend #col3_content #content-core .pat-autotoc .autotoc-level-4::before {
  padding-right: 0.5em;
  counter-increment: head-4;
  content: counter(head-1) "." counter(head-2) "." counter(head-3) "." counter(head-4);
}
#col3_content form.yform fieldset,
#content-core form.yform fieldset {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border-top: 1px solid #3d3d3d;
}
#col3_content form.yform fieldset legend,
#content-core form.yform fieldset legend {
  border: 0 none;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 1em;
  padding-right: 1em;
  color: #3d3d3d;
  margin-left: 2em;
  margin-bottom: 0.5em;
  display: inline;
  width: auto;
  font-size: 14px;
}
#col3_content form.yform fieldset.error .field-error,
#content-core form.yform fieldset.error .field-error {
  background-color: #f55;
}
#col3_content form.yform fieldset.error .field-error .errormessage,
#content-core form.yform fieldset.error .field-error .errormessage {
  margin: 0.25em;
  color: #fff;
  font-weight: 600;
}
#col3_content form.yform fieldset.error .field,
#content-core form.yform fieldset.error .field {
  background-color: rgba(255, 85, 85, 0.5);
}
#col3_content form.yform .field,
#content-core form.yform .field {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}
#col3_content form.yform .field:hover,
#content-core form.yform .field:hover {
  background-color: #e6e6e6;
}
#col3_content form.yform .field.error,
#content-core form.yform .field.error {
  background-color: rgba(255, 85, 85, 0.5);
}
#col3_content form.yform .field.error .field-error,
#content-core form.yform .field.error .field-error {
  background-color: #f55;
}
#col3_content form.yform .field.error .field-error .errormessage,
#content-core form.yform .field.error .field-error .errormessage {
  margin: 0.25em;
  color: #fff;
  font-weight: 600;
}
#col3_content form.yform .field label,
#content-core form.yform .field label {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-weight: 600;
  color: #3d3d3d;
  margin-bottom: 0.25em;
}
#col3_content form.yform .field label.required,
#content-core form.yform .field label.required,
#col3_content form.yform .field label > .required,
#content-core form.yform .field label > .required {
  color: #3d3d3d !important;
  vertical-align: middle;
}
#col3_content form.yform .field label.required::after,
#content-core form.yform .field label.required::after,
#col3_content form.yform .field label > .required::after,
#content-core form.yform .field label > .required::after {
  color: #8a0f14;
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  height: 20px;
  width: 20px;
  content: 'info' !important;
  vertical-align: text-bottom;
}
#col3_content form.yform .field .help,
#content-core form.yform .field .help {
  font-weight: 400;
  font-style: italic;
  display: block;
  color: #707070;
  margin-bottom: 0.25em;
}
#col3_content form.yform .field input,
#content-core form.yform .field input {
  padding: 0.25em;
  border: 1px solid #ccc;
  border-radius: 0;
}
#col3_content form.yform .field input:focus-visible,
#content-core form.yform .field input:focus-visible {
  border: 1px solid #003d7f;
  border-radius: 0;
  outline: 1px solid #003d7f;
}
#col3_content form.yform .field select,
#content-core form.yform .field select {
  padding: 0.25em;
}
#col3_content form.yform .field input[type="text"].datepicker,
#content-core form.yform .field input[type="text"].datepicker {
  width: 10em;
}
#col3_content form.yform .field button[type="button"].ui-datepicker-trigger,
#content-core form.yform .field button[type="button"].ui-datepicker-trigger {
  width: 3em;
  padding: 0.25em 0.5em;
  margin-top: -2px;
}
#col3_content form.yform .field .check-agree,
#content-core form.yform .field .check-agree {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}
#col3_content form.yform .field .check-agree input[type="checkbox"],
#content-core form.yform .field .check-agree input[type="checkbox"],
#col3_content form.yform .field .check-agree label,
#content-core form.yform .field .check-agree label {
  display: inline-block;
}
#col3_content form.yform .field .check-agree input[type="checkbox"],
#content-core form.yform .field .check-agree input[type="checkbox"] {
  margin-right: 0.5em;
  margin-left: 2em;
  line-height: 1.5em;
}
#col3_content form.yform .field .check-agree input[type="checkbox"]::before,
#content-core form.yform .field .check-agree input[type="checkbox"]::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  content: 'info' !important;
  color: #8a0f14;
  margin-left: -1.25em;
  margin-top: -4px;
}
#col3_content form.yform input[type="submit"],
#content-core form.yform input[type="submit"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media screen and (min-width: 801px) {
  #mainmenu {
    position: absolute;
    padding-left: 200px;
    width: 865px;
    color: #003d7f;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
#mainmenu .col_w90l > .subcl > ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  list-style-type: none;
  list-style-position: inside;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  background: none;
  line-height: 2.07142857em;
  font-size: 1.08328571em;
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    text-align: center;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul > li {
    background-color: #003d7f;
    color: #fff;
    border: 1px solid #fff;
  }
  #mainmenu .col_w90l > .subcl > ul > li:hover {
    background-color: #e6e6e6;
    color: #069;
  }
  #mainmenu .col_w90l > .subcl > ul > li:hover a {
    color: #003d7f;
  }
  #mainmenu .col_w90l > .subcl > ul > li a:hover {
    color: #003d7f;
    text-decoration: none;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul > li:hover {
    background-color: #003d7f;
    color: #fff;
  }
  #mainmenu .col_w90l > .subcl > ul > li:hover a {
    color: #fff;
  }
  #mainmenu .col_w90l > .subcl > ul > li:hover a:hover {
    text-decoration: none;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree {
    position: relative;
    display: inline-block;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree .submainmenu {
    display: none;
    position: absolute;
    background-color: #fa0;
    max-width: 450px;
    z-index: 1;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu {
    display: block;
    list-style-type: none;
    list-style-position: inside;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-top: -3px;
    background-color: #e6e6e6;
    width: max-content;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > ul li {
    border-bottom: 1px solid #fff;
    list-style-type: none;
    list-style-position: inside;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    line-height: 1.66em;
    text-indent: -1em;
    padding: 0 0.25em;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > ul li a {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1em;
    margin-right: 1em;
    color: #3d3d3d;
    font-weight: normal;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > ul li:hover {
    background-color: #ccc;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .col_w50l {
    border-right: 1px solid #fff;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl ul,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl ul li,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr ul li {
    border-bottom: 1px solid #fff;
    list-style-type: none;
    list-style-position: inside;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    line-height: 1.66em;
    text-indent: -1em;
    padding: 0 0.25em;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl ul li a,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr ul li a {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 1em;
    margin-right: 1em;
    color: #3d3d3d;
    font-weight: normal;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl ul li:hover,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr ul li:hover {
    background-color: #ccc;
  }
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcl ul li,
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree:hover .submainmenu > .subcolumns .subcr ul li {
    width: 100%;
    font-size: 1em;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul > li.has_subtree .submainmenu {
    display: none;
  }
}
#mainmenu .col_w90l > .subcl > ul > li a {
  font-weight: bold;
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul > li a {
    color: #003d7f;
    padding: 0 0.8em;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul > li a {
    color: #fff;
    display: block;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.desktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.desktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.desktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.desktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.desktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.desktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.nodesktop {
    visibility: visible;
    position: initial;
    top: initial;
    left: initial;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.nodesktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .mobile.nodesktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.nodesktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.nodesktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (min-width: 801px) {
  #mainmenu .col_w90l > .subcl > ul .nomobile.nodesktop {
    visibility: hidden;
    position: absolute;
    top: -32600px;
    left: -32600px;
  }
}
@media screen and (max-width: 800px) {
  #mainmenu .col_w10r {
    width: 0;
  }
}
#global_statusmessage .portalMessage,
#content .portalMessage {
  border: 1px solid #f55;
  background-color: #e6e6e6;
  color: #3d3d3d;
}
#global_statusmessage .portalMessage.info,
#content .portalMessage.info {
  border: 1px solid #5f5;
}
#global_statusmessage .portalMessage.info strong,
#content .portalMessage.info strong,
#global_statusmessage .portalMessage.info dt,
#content .portalMessage.info dt {
  background-color: #5f5;
}
#global_statusmessage .portalMessage.info strong::before,
#content .portalMessage.info strong::before,
#global_statusmessage .portalMessage.info dt::before,
#content .portalMessage.info dt::before {
  content: 'announcement' !important;
}
#global_statusmessage .portalMessage.warning,
#content .portalMessage.warning {
  border: 1px solid #ff9;
}
#global_statusmessage .portalMessage.warning strong,
#content .portalMessage.warning strong,
#global_statusmessage .portalMessage.warning dt,
#content .portalMessage.warning dt {
  background-color: #ff9;
}
#global_statusmessage .portalMessage.warning strong::before,
#content .portalMessage.warning strong::before,
#global_statusmessage .portalMessage.warning dt::before,
#content .portalMessage.warning dt::before {
  content: 'warning' !important;
}
#global_statusmessage .portalMessage.error,
#content .portalMessage.error {
  background-color: #f55;
  color: #ededed;
}
#global_statusmessage .portalMessage.error strong::before,
#content .portalMessage.error strong::before,
#global_statusmessage .portalMessage.error dt::before,
#content .portalMessage.error dt::before {
  content: 'error' !important;
}
#global_statusmessage .portalMessage strong,
#content .portalMessage strong,
#global_statusmessage .portalMessage dt,
#content .portalMessage dt {
  margin-right: 0.5em;
  height: 100%;
  float: left;
}
@media screen and (min-width: 801px) {
  #global_statusmessage .portalMessage strong,
  #content .portalMessage strong,
  #global_statusmessage .portalMessage dt,
  #content .portalMessage dt {
    width: 20%;
  }
}
@media screen and (max-width: 800px) {
  #global_statusmessage .portalMessage strong,
  #content .portalMessage strong,
  #global_statusmessage .portalMessage dt,
  #content .portalMessage dt {
    min-width: 8.5em;
  }
}
#global_statusmessage .portalMessage strong::before,
#content .portalMessage strong::before,
#global_statusmessage .portalMessage dt::before,
#content .portalMessage dt::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-right: 0.25em;
  vertical-align: middle;
  display: inline;
}
@media screen and (min-width: 801px) {
  #global_statusmessage .portalMessage,
  #content .portalMessage {
    margin: 0.5em 1em;
  }
}
@media screen and (max-width: 800px) {
  #global_statusmessage .portalMessage,
  #content .portalMessage {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
}
#content-core .portalMessage {
  border: 1px solid #f55;
  background-color: #e6e6e6;
  color: #3d3d3d;
}
#content-core .portalMessage.info {
  border: 1px solid #5f5;
}
#content-core .portalMessage.info strong,
#content-core .portalMessage.info dt {
  background-color: #5f5;
}
#content-core .portalMessage.info strong::before,
#content-core .portalMessage.info dt::before {
  content: 'announcement' !important;
}
#content-core .portalMessage.warning {
  border: 1px solid #ff9;
}
#content-core .portalMessage.warning strong,
#content-core .portalMessage.warning dt {
  background-color: #ff9;
}
#content-core .portalMessage.warning strong::before,
#content-core .portalMessage.warning dt::before {
  content: 'warning' !important;
}
#content-core .portalMessage.error {
  background-color: #f55;
  color: #ededed;
}
#content-core .portalMessage.error strong::before,
#content-core .portalMessage.error dt::before {
  content: 'error' !important;
}
#content-core .portalMessage strong,
#content-core .portalMessage dt {
  margin-right: 0.5em;
  height: 100%;
  float: left;
}
@media screen and (min-width: 801px) {
  #content-core .portalMessage strong,
  #content-core .portalMessage dt {
    width: 20%;
  }
}
@media screen and (max-width: 800px) {
  #content-core .portalMessage strong,
  #content-core .portalMessage dt {
    min-width: 8.5em;
  }
}
#content-core .portalMessage strong::before,
#content-core .portalMessage dt::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-right: 0.25em;
  vertical-align: middle;
  display: inline;
}
#content-core .configlets dl.portalMessage {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
}
#content-core .configlets dl.portalMessage dt {
  margin-right: 1em;
}
#content-core .configlets dl.portalMessage dt::before {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  margin-right: 0.25em;
  vertical-align: middle;
}
#content-core .configlets dl.portalMessage dt,
#content-core .configlets dl.portalMessage dd {
  padding: 0.25em;
}
#huub_search {
  background-color: #e6e6e6;
  color: #3d3d3d;
}
@media screen and (min-width: 801px) {
  #huub_search {
    margin-top: -3px;
    height: 60px;
  }
}
@media screen and (max-width: 800px) {
  #huub_search {
    margin: 0;
  }
}
@media screen and (min-width: 801px) {
  #huub_search .searchbar {
    padding: 1.2142em 1em 1.2142em 1em;
    /* depending on font-size */
  }
}
@media screen and (max-width: 800px) {
  #huub_search .searchbar {
    padding: 5px;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .col_w23l {
    width: 30%;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .col_w23l .subcl {
    padding-right: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .col_w60l {
    width: 69%;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .col_w60l .subcl {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
#huub_search .simpleSearch select,
#huub_search .simpleSearch input {
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
}
#huub_search .simpleSearch .buttonform {
  height: 22px;
  width: 22px;
}
#huub_search .simpleSearch .buttonform button {
  width: 100%;
  height: 100%;
  background-color: #fff;
  cursor: pointer;
  font-size: 2.9166em;
  /*35px*/
  border: 2px solid #fff;
}
#huub_search .simpleSearch .buttonform button:hover {
  border: 2px solid #ddc23e;
}
#huub_search .simpleSearch .buttonform button span {
  position: relative;
  top: -0.32em;
}
#huub_search .simpleSearch .select {
  height: 26px;
  vertical-align: top;
}
#huub_search .simpleSearch .select select option {
  background-color: #fff;
  padding: 0 0.3em;
}
#huub_search .simpleSearch .select .ui-icon {
  width: 26px;
  height: 26px;
  top: 25%;
}
#huub_search .simpleSearch .select .ui-selectmenu-button {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #fff;
  height: 26px;
  line-height: 26px;
  width: 100%;
  font-size: 14px;
  font-family: Verdana, Lucida, Helvetica, Arial, sans-serif;
}
#huub_search .simpleSearch .select .ui-selectmenu-button.ui-corner-all {
  border-radius: 0;
}
#huub_search .simpleSearch .select .ui-selectmenu-button .ui-icon {
  right: 0.5em;
  left: auto;
  margin-top: -8px;
  position: absolute;
}
#huub_search .simpleSearch .select .ui-selectmenu-button .ui-selectmenu-text {
  line-height: inherit;
}
@media screen and (min-width: 801px) {
  #huub_search .simpleSearch .select .ui-selectmenu-button .ui-selectmenu-text {
    padding: 0 30px 0 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .select .ui-selectmenu-button .ui-selectmenu-text {
    padding: 0 10px 0 0.5em;
  }
}
@media screen and (min-width: 801px) {
  #huub_search .simpleSearch .colGroup > .subcl {
    padding-right: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .colGroup > .subcl .col_w95l {
    width: 83%;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .colGroup > .subcl .col_w5r {
    width: 22px;
  }
}
@media screen and (max-width: 800px) {
  #huub_search .simpleSearch .colGroup > .subcl .col_w5r .subcr {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 801px) {
  #huub_search .simpleSearch .colGroup .subcr {
    padding-left: 0;
  }
}
#huub_search .simpleSearch .LSBox {
  float: none;
  text-align: left;
}
#huub_search .simpleSearch .textform {
  height: 26px;
}
#huub_search .simpleSearch .textform input {
  width: 100%;
  height: 100%;
  border: 0 none;
  padding: 0 0.3em;
  color: #3d3d3d;
  font-size: 1em;
  background-color: #fff;
  background-image: none;
}
#huub_search .simpleSearch .textform input:focus-visible {
  border-radius: 0;
  outline: 1px solid #003d7f;
}
#huub_search .ui-selectmenu-menu {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  background-color: #fff;
  border: 1px solid #3d3d3d;
  overflow: hidden;
}
#huub_search .ui-selectmenu-menu ul {
  border: 0 none;
  overflow: hidden;
}
#huub_search .ui-selectmenu-menu ul.ui-corner-bottom {
  border-radius: 0;
}
#huub_search .ui-selectmenu-menu ul li.ui-menu-item {
  font-size: 14px;
}
#huub_search .ui-selectmenu-menu ul li.ui-menu-item .ui-menu-item-wrapper {
  padding: 3px 1em 3px 0.4em;
}
#huub_search .ui-selectmenu-menu ul li.ui-menu-item .ui-state-active,
#huub_search .ui-selectmenu-menu ul li.ui-menu-item .ui-state-focus {
  border: 0 none;
  background-color: #069;
  background-image: -moz-linear-gradient(top, #069, 3d 7f);
  background-image: -webkit-gradient(linear, 0, 0, 0 100%, from(#069), to(#003d7f));
  background-image: -webkit-linear-gradient(top, #069, #003d7f);
  background-image: linear-gradient(to bottom, #069, #003d7f);
  background-repeat: repeat-x;
  color: #fff;
  margin: 0;
}
/* instant website search */
#LSResult {
  width: 100%;
  background-color: #f4f4f4;
  padding: 0.3em;
  z-index: 1000;
  position: relative;
  overflow: hidden;
}
#LSResult ul.LSTable {
  list-style: disc inside;
}
/*# sourceMappingURL=++theme++hub-ub-theme/hubub2/deploy/less/theme-compiled.less.map */